import React from 'react'
import { UseFormRegister, FieldValues, FieldErrors, RegisterOptions } from 'react-hook-form';

interface Props{
    FieldName: string,
    Placeholder: string;
    Width? : string;
    Register: UseFormRegister<FieldValues>;
    Errors: FieldErrors<FieldValues>;
    Validation?: RegisterOptions;
    Disable? : boolean
}

export default function BCheckBox({FieldName, Placeholder, Register, Errors, Validation,Width, Disable }: Props) {
    const errorMessage = Errors[FieldName]?.message as string | undefined;
  return (
    <div className={` ${Width ? Width : 'w-1/2'} relative my-2 pt-2 inline-block`}>
    <input type="checkbox" id="" {...Register(FieldName, Validation)}/><span className='px-2 text-xs'>{Placeholder}</span>
    {Errors[FieldName] && <p className="text-xs text-red-600 mt-1">{errorMessage}</p>}
  </div>
  )
}
