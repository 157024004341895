import React, { ReactNode } from 'react'

interface Parameters{
    Content :ReactNode

}

export default function BModal({Content} : Parameters) {
  return (
    <div className='fixed inset-0 bg-opacity-50  w-full z-[45] bg-blue-300 flex justify-center pt-[100px] p-4 overflow-hidden'>
            <div className='w-full md:w-4/12 bg-white rounded-xl p-4 flex flex-col h-min'>
                {Content}
            </div>
    </div>
  )
}
