import { deleteDoc, doc, updateDoc } from "firebase/firestore";

import { db } from "../firebaseConfig";


export async function UpdateInvoice(data: any, id: string) {
    try {
        const docRef = doc(db, 'Invoices', id);

        await updateInvoice(docRef, data);

        return { success: true }

    } catch (err) {
        
        console.log(err);
        return { errorMessage: 'Error on updating check logs', success: false }
    }



}

const updateInvoice = async (docRef: any, payload: any) => {

    return await updateDoc(docRef, payload);
}

export const DeleteInvoice = async (id: string) => {

    try {
        const docRef = doc(db, 'Invoices', id);

        await deleteDoc(docRef);

        return { success: true };

    } catch (err) {
        console.log(err);
        return { errorMessage: 'Error on deleting check logs', success: false }
    }

}

