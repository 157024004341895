import { collection, doc, getDoc, getDocs } from "firebase/firestore"
import { db } from "../firebaseConfig";
import { UserDAO } from "../_models/user";


export const readUsers = async () => {

    const querySnap = await getDocs(collection(db, 'Users'));
    const users: UserDAO[] = querySnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
    })) as UserDAO[];

    return users;

}

export const readUser = async (id :string) =>{
    try
    {

        const docRef = doc(db, 'Users', id);

        const userDoc = await getDoc(docRef);

        if(!userDoc.exists()){
            return { errorMessage: 'This user does not exist', success: false }
        }
    
        const user : UserDAO = {
            id : userDoc.id,
            ...userDoc.data()
        } as UserDAO

        return { data : user, success: true };

    }catch(err){
        console.error(err);
        return { errorMessage: 'Error on getting check logs', success: false }
    }
  
}