import React, { useEffect, useState } from 'react'
import Spinner from '../../_components/spinner';
import { IoDocuments, IoSearchSharp } from "react-icons/io5";
import { LuPackagePlus, LuPackageMinus } from "react-icons/lu";
import BButton, { ButtonType } from '../../_components/button';
import { FieldValues, useForm } from 'react-hook-form';
import BModal from '../../_components/modal';
import { ProductDAO, StorageDAO } from '../../_models/product';
import BInputField from '../../_components/formfields/BInputField';
import { validateNonZero, validateNotEmpty } from '../../utility/validations';
import { readProductByCode } from '../../_services/productreadservice';
import BSelectField from '../../_components/formfields/BSelectField';
import { readStorage } from '../../_services/settingsreadservice';
import { LocationDAO } from '../../_models/invertory';
import { CreateLocationStorage } from '../../_services/storagecreationservice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../_components/authProvider';


export default function DashboardView() {
  const [processing, setProcessing] = useState(false);
  const [search, setSearch] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductDAO>();
  const [inputSearch, setInputSearch] = useState<string>("");
  const user = useAuth();  

  const navigate = useNavigate();

  useEffect(() => {
    setProcessing(true);
    
    setProcessing(false);
  }, []);

  const handleSearch = async () => {

    if (inputSearch === "") {
      return;
    }

    setProcessing(true);

    const result = await readProductByCode(inputSearch);

    setProcessing(false);

    if (!result.success) {
      toast.error(result.errorMessage);
      return;
    }

    setSearch(false);
    setProduct(result.data);

  }

  const onSearchInput = (input: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(input?.target?.value);
  }

  //Renders

  const _renderSearch = (
    <div className='w-full flex flex-col gap-3 p-4'>
      <input type="text" className='w-full border border-gray-300 rounded-xl p-2 focus:outline-none focus:border-slate-600' onChange={onSearchInput} placeholder='Type the part number' />
      <BButton CallBack={() => setSearch(false)} ButtonStyle={ButtonType.Secondary} Label='Cancel' />
      <BButton CallBack={handleSearch} ButtonStyle={ButtonType.Primary} Label='Search' />

    </div>
  )

  const _renderProductScreen = (
    <div className='w-full flex flex-col gap-3 p-3'>
      <p className='text-sm'>Search Result</p>
      <hr />
      <table className='w-full text-xs'>
        <tbody>
          <tr><td>Product : </td><td>{product?.name}</td></tr>
          <tr><td>Part Number : </td><td>{product?.code}</td></tr>
          <tr><td>Category : </td><td>{product?.category}</td></tr>
          <tr><td>Brand : </td><td>{product?.brand}</td></tr>
          <tr><td>Price : </td><td>R {product?.price}</td></tr>
        </tbody>
      </table>
      <BButton CallBack={() => setProduct(undefined)} ButtonStyle={ButtonType.Secondary} Label='Cancel' />
    </div>
  )

 


  return (
    <div className='flex flex-col overflow-auto'>
      {search && <BModal Content={_renderSearch} />}
      {processing && <Spinner />}
      {product && <BModal Content={_renderProductScreen} />}
      <h3>Dashboard</h3>
      <hr />
      <div className='flex flex-row flex-wrap mt-4'>
        <div className='w-1/2 md:w-4/12 p-2'>
          <button onClick={() => setSearch(true)} className='w-full pt-10 md:pt-20 flex flex-col bg-gradient-to-bl from-blue-200 to-white   bg-white rounded-xl p-4 h-[150px] md:h-[240px] items-center text-center align-middle'>
            <IoSearchSharp size={50} />
            <p>Search</p>
          </button>
        </div>
        {['admin', 'superadmin','user'].includes(user.user?.Role.toLowerCase()??'') && <div className='w-1/2 md:w-4/12 p-2'>
          <button onClick={() => navigate('/sales')} className='w-full pt-10 md:pt-20 flex flex-col bg-gradient-to-bl from-blue-200 to-white rounded-xl p-4 h-[150px] md:h-[240px] items-center text-center align-middle'>
            <IoDocuments size={50} />
            <p>Invoices</p>
          </button>
        </div>}
        <div className='w-1/2 md:w-4/12 p-2'>
          <button onClick={()=> {navigate('/pick')}} className='w-full pt-10 md:pt-20 flex flex-col bg-gradient-to-bl from-blue-200 to-white bg-white rounded-xl p-4 h-[150px] md:h-[240px] items-center text-center align-middle'>
            <LuPackageMinus size={50} />
            <p>Pick</p>
          </button>
        </div>
        <div className='w-1/2 md:w-4/12 p-2'>
          <button onClick={() => navigate('/receive')} className='w-full pt-10 md:pt-20 flex flex-col bg-gradient-to-bl from-blue-200 to-white bg-white rounded-xl p-4 h-[150px] md:h-[240px] items-center text-center align-middle'>
            <LuPackagePlus size={50} />
            <p>Receive</p>
          </button>
        </div>
      </div>
    </div>
  )
}

