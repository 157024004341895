import React, { useEffect, useState } from 'react'
import { ProductDAO } from '../../_models/product'
import BTableData from '../../_components/btable';
import BTablecolumn from '../../_components/btablecolumns';
import { Datatype } from '../../_models/table';
import { FaFilter, FaSearch, FaPlusCircle, FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { readProducts } from '../../_services/productreadservice';
import Spinner from '../../_components/spinner';
import Modal from '../../_components/modal';
import { DeleteProduct } from '../../_services/productmanagementservice';
import { toast } from 'react-toastify';
import { FaPencil } from 'react-icons/fa6';

export default function ProductsView() {
  const [productlist, setProductlist] = useState<ProductDAO[]>([]);
  const [product, setProduct] = useState<ProductDAO>();
  const [processing, setProcessing] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const navigate = useNavigate();

  const isMobile = window.innerWidth < 640

  useEffect(() => {
    setProcessing(true);
    // Fetch and display data on component mount
    const fetchData = async () => {
      const data = await readProducts();
      setProductlist(data);
      setProcessing(false);
    };
    fetchData();
  }, []);

  const tableButton = (data: any) => (
    <div className='flex flex-row gap-2'>
      <button className='text-xs rounded-full bg-gradient-to-tr from-red-400 to-white flex gap-2 py-1 px-2' onClick={() => deleteProduct(data)}><FaTrash /> Delete </button>
      <button className='text-xs rounded-full bg-gradient-to-tr from-blue-400 to-white flex gap-2 py-1 px-2' onClick={() => navigate(`/product/${data.id}`)}><FaEdit /> Edit </button>
    </div>
  )

  const deleteContent = (
    <div className='w-full flex flex-col justify-center text-center'>
      <h3 className='mt-3'>Are you sure you want to delete?</h3><br />
      <p className='text-sm'>Please not this info may not be recovered</p>
      <div className='flex flex-row gap-2 w-full justify-center mt-9'>
        <button className='text-xs w-[80px] rounded-full bg-gradient-to-tr from-blue-400 to-white flex gap-2 py-1 px-2' onClick={() => handleDelete()}><FaTrash /> Yes </button>
        <button className='text-xs w-[80px] rounded-full bg-gradient-to-tr from-red-400 to-white flex gap-2 py-1 px-2' onClick={() => setDeleteModal(false)}><FaEdit /> No </button>
      </div>
    </div>
  )

  const deleteProduct = (data: any) => {
    setProduct(data);
    setDeleteModal(true);
  }

  const handleDelete = async () => {

    setProcessing(true);
    const response = await DeleteProduct(product?.id || '');

    if (!response.success) {
      toast.error(response.errorMessage);
      setProcessing(false);
      return;
    }

    setDeleteModal(false);
    const data = await readProducts();
    setProductlist(data);

    setProcessing(false);

  }

  const _renderProducts = () => {
    return (
      productlist.map((prod) => (
        <div key={prod.id} className='w-full flex flex-row bg-gradient-to-r from-blue-100 to-white rounded-md mb-3'>
          <div className='bg-blue-300 w-[50px] rounded-l-md p-2 pl-4'>
            {prod.quantity}
          </div>
          <div className='w-10/12 flex justify-between'>
            <div className='p-1 pl-3 '>
              <p className='text-[12px]'>Part Number <span className='text-[10px] text-gray-500'>{prod.code}</span></p>
              <p className='text-[10px] text-gray-500'>Price : {prod.price}</p>
            </div>
            <div className='flex justify-end py-4 gap-2'>
              <button className='text-xs rounded-full bg-gradient-to-tr from-blue-300 to-white flex gap-2 py-1 px-2' onClick={() => navigate(`/product/${prod.id}`)}><FaEdit /> Edit</button>

              <button className='text-xs rounded-full bg-gradient-to-tr from-red-400 to-white flex gap-2 py-1 px-2' onClick={() => deleteProduct(prod)}><FaTrash /> Delete</button>
            </div>
          </div>
        </div>
      ))
    )
  }

  return (
    <div className='flex flex-col overflow-auto'>
      {processing && <Spinner />}
      {deleteModal && <Modal Content={deleteContent} />}
      <h3>Products</h3>
      <hr />
      <div className='flex w-full justify-between my-3'>
        <div className='flex flex-row gap-4 text-blue-400'><button><FaFilter /></button><button><FaSearch /></button></div>
        <div className='flex justify-end'>
          <button onClick={() => navigate('/product')} className='flex gap-2 w-[100] text-xs rounded-full bg-gradient-to-tr from-blue-400 to-white p-2'><FaPlusCircle /> Add Product </button>
        </div>
      </div>
      {isMobile && _renderProducts()}
      {!isMobile && <BTableData dataSource={productlist} actions={tableButton}>
        <BTablecolumn column='name' label='Name' />
        <BTablecolumn column='code' label='Part Number' />
        <BTablecolumn column='price' label='Price' />
        <BTablecolumn column='quantity' label='Current Quantity' />
        <BTablecolumn column='discount' label='On Discount' type={Datatype.Checkbox} />
        <BTablecolumn column='feature' label='Is Featured' type={Datatype.Checkbox} />
      </BTableData>
      }
    </div>
  )
}
