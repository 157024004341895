import React from 'react'
import { UseFormRegister, FieldValues, FieldErrors, RegisterOptions } from 'react-hook-form';

interface Props {
  FieldName: string,
  Placeholder: string;
  Register: UseFormRegister<FieldValues>;
  Errors: FieldErrors<FieldValues>;
  Validation?: RegisterOptions;
  Data?: any[]
  Default?: number
  onChange?: (data: any) => void
}

export default function BSelectField({ FieldName, Placeholder, Register, Errors, Validation, Data, onChange }: Props) {
  const errorMessage = Errors[FieldName]?.message as string | undefined;

  const handleOnChange = (event: any) => {
    if (onChange)
      onChange(event.target.value);
  }

  return (
    <div className="relative my-2">
      <select
        {...Register(FieldName, Validation)}
        className={`w-full border border-gray-300 rounded-xl p-[9.5px] focus:outline-none focus:border-slate-600 ${Errors[FieldName] ? 'border-red-500' : ''
          }`}
        id={FieldName}
        defaultValue={0}
        onChange={handleOnChange}
      >
        <option className='text-xs' value={0}>Select {Placeholder.toLowerCase()}</option>
        {
          Data && Data.map(Data =>
            <option key={Data?.id} value={Data?.description} className='text-xs'>{Data?.description}</option>
          )
        }
      </select>
      <label
        htmlFor={FieldName}
        className={` text-xs absolute transition-all duration-200 ease-in-out text-gray-500 pointer-events-none ${Errors[FieldName] ? 'text-red-500' : ''
          }`}
      >
        {Placeholder}
      </label>
      {Errors[FieldName] && <p className="text-xs text-red-600 mt-1">{errorMessage}</p>}
    </div>
  )
}
