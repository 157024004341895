import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { LocationDAO } from "../_models/invertory";
import { UpdateProduct } from "./productmanagementservice";
import { readLocations } from "./storagereadservice";
import { UpdateLocation } from "./storagemanageservice";
import { readProductByCode } from "./productreadservice";

export async function CreateLocationStorage(data: LocationDAO) {
    try {

        const result = await readProductByCode(data.code);

        if(!result.data)
        {
            return {success : false, errorMessage : result.errorMessage};
        }

        result.data.quantity = Number(result.data.quantity) + Number(data.quantity);        
       
        await UpdateProduct(result.data, result.data.id ?? '');

        const locations = await readLocations();

        let location = locations.find(f => f.code === data.code && f.storage === data.storage);

        if(location){
            location = {...location, quantity :  Number(location.quantity) + Number(data.quantity)}
        }

        console.log(location)

        location ? await UpdateLocation(location, location.id??'') : await addLocation(data.code,data.quantity, data.storage);

        return { success: true };


    } catch (err) {
        console.log(err);
        return { errorMessage: 'Error on creation check logs', success: false }
    }
}

const addLocation = async (
    code: string,
    quantity: number,
    storage: string,

) => {
    try {

        const docRef = await addDoc(collection(db, 'Locations'), {
            code,
            quantity,
            storage
        })
        return docRef

    } catch (err) {
        console.log('error saving location ', err);
        return null
    }

}


