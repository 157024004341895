import React, { useEffect, useState } from 'react'
import { FieldValues, RegisterOptions, useForm, UseFormRegisterReturn } from 'react-hook-form'
import BInputField from '../../_components/formfields/BInputField';
import BSelectField from '../../_components/formfields/BSelectField';
import BCheckBox from '../../_components/formfields/BCheckBox';
import BTextBox from '../../_components/formfields/BTextBox';
import BButton, { ButtonType } from '../../_components/button';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateProduct } from '../../_services/productcreationservice';
import { BrandDAO, CategoryDAO, ProductDAO } from '../../_models/product';
import Spinner from '../../_components/spinner';
import CloudinaryUploadWidget from '../../_components/uploadImage';
import { validateNumberNonZero } from '../../utility/validations';
import { readProduct } from '../../_services/productreadservice';
import { readCategories, readBrands } from '../../_services/settingsreadservice';
import { UpdateProduct } from '../../_services/productmanagementservice';


export default function ProductView() {
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<FieldValues>();
  const [processing, setProcessing] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [brandList, setBrandList] = useState<BrandDAO[]>([]);
  const [categoryList, setCategoryList] = useState<CategoryDAO[]>([]);

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    setValue('quantity', 0);
    if (id) {
      setProcessing(true);

      const fetchUser = async () => {
        const product = await readProduct(id);

        if (!product.success) {
          setProcessing(false);
          return;
        }

        setValue('description', product.data?.description)
        setValue('name', product.data?.name)
        setValue('brand', product.data?.brand)
        setValue('category', product.data?.category)
        setValue('code', product.data?.code)
        setValue('discount', product.data?.discount)
        setValue('discountPrice', product.data?.discountPrice)
        setValue('featured', product.data?.featured)
        setValue('imageUrl', product.data?.imageUrl)
        setValue('images', product.data?.images)
        setValue('price', product.data?.price)
        setValue('quantity', product.data?.quantity)
        setValue('promote', product.data?.promote);

        setImages(product.data?.images || [])
        setProcessing(false);

      }
      fetchUser();
    }
    fetchData();
  }, [])

  const fetchData = async () => {
    setProcessing(true);
    setCategoryList(await readCategories());
    setBrandList(await readBrands());
    setProcessing(false);
  }

  const handleSave = async (data: FieldValues) => {

    setProcessing(true);

    const response = !id ? await CreateProduct(data as ProductDAO) : await UpdateProduct(data as ProductDAO, id)

    setProcessing(false);
    
    if (!response.success) {
      console.log(response.errorMessage);
      return;
    }

    navigate('/products');
  }

  const cancelForm = () => {
    navigate('/products');
  }

  const handleUpload = (result: any) => {
    const imageList = [...images, result.secure_url]
    console.log(imageList)
    setImages(imageList);
    setValue('images', imageList);
  };

  return (
    <div className='flex justify-center w-full mb-40'>
      {processing && <Spinner />}
      <div className='w-full md:w-7/12 rounded-xl bg-white p-4 mb-[100px]'>
        <h3>Product Details</h3>
        <hr />
        <form onSubmit={handleSubmit(handleSave)} className='flex flex-col gap-2 mt-4'>
          <div className='flex flex-col md:flex-row gap-2 w-full'>
            <div className='p-1 w-full md:w-1/2 flex flex-col gap-3'>
              <BInputField
                FieldName='name'
                Placeholder='Product Name'
                Register={register}
                Errors={errors}
                Validation={{
                  required: 'This field is required'
                }}
              />
              <BInputField
                FieldName='code'
                Placeholder='Part Number'
                Register={register}
                Errors={errors}
                Validation={{
                  required: 'This field is required'

                }}
              />
              <BInputField
                FieldName='price'
                Placeholder='Product Price'
                Register={register}
                Errors={errors}
                Validation={{
                  required: 'This field is required',
                  validate: (value) => {
                    if (validateNumberNonZero(value)) {
                      return 'This must be digit value of greater than 0'
                    }

                    return true
                  }
                }}
              />
              <BInputField
                FieldName='discountPrice'
                Placeholder='Discount Price'
                Register={register}
                Errors={errors}
                Validation={{
                  validate: (value) => {
                    if (validateNumberNonZero(value)) {
                      return 'This must be digit value of greater than 0'
                    }
                    return true
                  }
                }
                }
              />
            </div>
            <div className='p-1 w-full md:w-1/2  flex flex-col gap-3'>
              <BSelectField Data={brandList} FieldName={'brand'} Placeholder={'Brand'} Register={register} Errors={errors} />
              <BSelectField Data={categoryList} FieldName={'category'} Placeholder={'Category'} Register={register} Errors={errors} />
              <BInputField
                FieldName='quantity'
                Placeholder='Available Quantity'
                Register={register}
                Errors={errors}
                Disable={true}
                Validation={{
                  required: 'This field is required',
                  validate: (value) => {
                    if (validateNumberNonZero(value)) {
                      return 'This must be digit value of greater than 0'
                    }

                    return true
                  }
                }}
              />
              <div className='flex flex-row flex-wrap w-full'>
                <BCheckBox
                  FieldName='discount'
                  Placeholder='On Discount'
                  Register={register}
                  Errors={errors}
                />
                <BCheckBox
                  FieldName='promote'
                  Placeholder='Promote'
                  Register={register}
                  Errors={errors}
                />
                <BCheckBox
                  FieldName='featured'
                  Placeholder='Featured'
                  Register={register}
                  Errors={errors}
                />
              </div>

            </div>

          </div>
          <div className='w-full'>
            <BTextBox FieldName={'description'} Placeholder={'Description'} Register={register} Errors={errors}
            />
          </div>
          <h5>Images</h5>
          <hr />
          <div className='flex flex-col mb-4'>
            <div className='w-full flex flex-row flex-wrap gap-2'>
              <CloudinaryUploadWidget onUpload={handleUpload} />
            </div>
            <div className='w-full flex flex-row pt-3 gap-2'>
              {
                images.map((image) => (
                  <img key={image} src={image} className='w-[100px] h-[100px]' alt="image" />
                ))
              }
            </div>
          </div>
          <div className='flex gap-3 w-full'>

            <BButton CallBack={() => cancelForm()} Width='w-[100px]' ButtonStyle={ButtonType.Secondary} Label={'Cancel'} />
            <BButton Submit={() => { }} Width='w-[100px]' ButtonStyle={ButtonType.Primary} Label={'Save'} />
          </div>
        </form>
      </div>
    </div>
  )
}
