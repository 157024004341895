
import { CiSquareCheck,CiSquareRemove } from "react-icons/ci";
import React from 'react'
import { TableColumnProps, Datatype } from "../_models/table";

interface TableDataProps {
    dataSource: any[],
    children: React.ReactElement<TableColumnProps>[]
    actions?: (row: any) => React.ReactNode
}

export default function BTableData({ dataSource, children, actions }: TableDataProps) {

    const columns = React.Children.toArray(children) as React.ReactElement<TableColumnProps>[];

    const renderCellContent = (value: any, type?: Datatype) => {
        if (!type)
            return value;

        switch (type) {
            case Datatype.Checkbox:
                return value ? <CiSquareCheck size={20} className='text-green-600'/> : <CiSquareRemove size={20} className='text-red-600'/>
            case Datatype.Currency:
                return 'R '+ value
        }
    }

    return (
        <table className='w-full'>
            <thead className='bg-blue-300'>
                <tr>
                    {columns.map((col, index) => (
                        <th key={index}>{col.props.label}</th>
                    ))}
                    {actions && (
                                <th>
                                   
                                </th>
                            )}
                </tr>
            </thead>
            <tbody className='bg-white'>
                {
                    dataSource.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {
                                columns.map((col, colIndex) => (
                                    <td className='border-b py-2 px-3 border-slate-300' key={colIndex}>
                                        {renderCellContent(row[col.props.column], col.props.type)}
                                    </td>
                                ))
                            }
                            {actions && (
                                <td className='border-b py-2 px-3 border-slate-300'>
                                    {actions(row)}
                                </td>
                            )}
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
};
