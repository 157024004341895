import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { UserDAO } from '../../_models/user';
import { FaFilter, FaSearch, FaPlusCircle, FaTrash, FaEdit } from 'react-icons/fa';
import BTableData from '../../_components/btable';
import BTablecolumn from '../../_components/btablecolumns';
import { readUsers } from '../../_services/userreadservice';
import Spinner from '../../_components/spinner';
import { DeleteUser } from '../../_services/usermanagementservice';
import Modal from '../../_components/modal';
import { toast } from 'react-toastify';

export default function UsersView() {
  const [userlist, setUserlist] = useState<UserDAO[]>([]);
  const [user, setUser] = useState<UserDAO>();
  const [processing, setProcessing] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const navigate = useNavigate();

  const isMobile = window.innerWidth < 640

  useEffect(() => {
    setProcessing(true);
    // Fetch and display data on component mount
    const fetchData = async () => {
      const data = await readUsers();
      setUserlist(data);
      setProcessing(false);
    };
    fetchData();

  }, []);

  const tableButton = (data: any) => (
    <div className='flex flex-row gap-2'>
      <div className='flex flex-row gap-2'>
        <button className='text-xs rounded-full bg-gradient-to-tr from-red-400 to-white flex gap-2 py-1 px-2' onClick={() => deleteUser(data)}><FaTrash /> Delete </button>
        <button className='text-xs rounded-full bg-gradient-to-tr from-blue-400 to-white flex gap-2 py-1 px-2' onClick={() => navigate(`/user/${data.id}`)}><FaEdit /> Edit </button>
      </div>
    </div>
  )

  const deleteContent = (
    <div className='w-full flex flex-col justify-center text-center'>
      <h3 className='mt-3'>Are you sure you want to delete?</h3><br />
      <p className='text-sm'>Please not this info may not be recovered</p>
      <div className='flex flex-row gap-2 w-full justify-center mt-9'>
        <button className='text-xs w-[80px] rounded-full bg-gradient-to-tr from-blue-400 to-white flex gap-2 py-1 px-2' onClick={() => handleDelete()}><FaTrash /> Yes </button>
        <button className='text-xs w-[80px] rounded-full bg-gradient-to-tr from-red-400 to-white flex gap-2 py-1 px-2' onClick={() => setDeleteModal(false)}><FaEdit /> No </button>
      </div>
    </div>
  )

  const deleteUser = (data: any) => {
    setUser(data);
    setDeleteModal(true);
  }

  const handleDelete = async () => {

    setProcessing(true);
    const response = await DeleteUser(user?.id || '');

    if (!response.success) {
      toast.error(response.errorMessage);
      setProcessing(false);
      return;
    }

    setDeleteModal(false);
    const data = await readUsers();
    setUserlist(data);

    setProcessing(false);

  }


  const _renderUsers = () => {
    return (
      userlist.map((use) => (
        <div key={use.id} className='w-full flex flex-row bg-gradient-to-r from-blue-100 to-white rounded-md mb-3'>

          <div className='w-full flex justify-between'>
            <div className='p-1 pl-3 '>
              <p className='text-[12px]'><span className='text-[10px] text-gray-500'>{use.name} {use.surname}</span></p>
              <p className='text-[10px] text-gray-500'>Email : {use.emailAddress}</p>
            </div>
            <div className='flex justify-end py-4 gap-2'>
              <button className='text-xs rounded-full bg-gradient-to-tr from-blue-300 to-white flex gap-2 py-1 px-2' onClick={() => navigate(`/user/${use.id}`)}><FaEdit /> Edit</button>

              <button className='text-xs rounded-full bg-gradient-to-tr from-red-400 to-white flex gap-2 py-1 px-2' onClick={() => deleteUser(use)}><FaTrash /> Delete</button>
            </div>
          </div>
        </div>
      ))
    )
  }

  return (

    <div className='flex flex-col overflow-auto'>
      {processing && <Spinner />}
      {deleteModal && <Modal Content={deleteContent} />}
      <h3>Users</h3>
      <hr />
      <div className='flex w-full justify-between my-3'>
        <div className='flex flex-row gap-4 text-blue-400'><button><FaFilter /></button><button><FaSearch /></button></div>
        <div className='flex justify-end'>
          <button onClick={() => navigate('/user')} className='flex gap-2 w-[100] text-xs rounded-full bg-gradient-to-tr from-blue-400 to-white p-2'><FaPlusCircle /> Add User </button>
        </div>
      </div>
      {isMobile && _renderUsers()}
      {!isMobile && <BTableData dataSource={userlist} actions={tableButton}>
        <BTablecolumn column='name' label='Name' />
        <BTablecolumn column='surname' label='Surname' />
        <BTablecolumn column='emailAddress' label='Email Address' />
        <BTablecolumn column='role' label='Role' />
      </BTableData>}
    </div>
  )
}
