import React from 'react'

interface Parameters {
    Submit?: () => void;
    CallBack?: () => void;
    ButtonStyle: ButtonType;
    Label: string
    Width?: string
}

export enum ButtonType {
    Primary,
    Secondary,
    Tertiary
}



export default function BButton({ Submit, CallBack, ButtonStyle, Label,Width }: Parameters) {

  
    return (
        <div className=''>
            <button
                type={Submit ? 'submit' : 'button'}
                onClick={CallBack}
                className={`${ButtonStyle === ButtonType.Primary ? `${Width ? Width : 'w-full'} rounded-full bg-gradient-to-tr from-blue-400 to-white p-2` :
                    ButtonStyle === ButtonType.Secondary ? `${Width ? Width : 'w-full'} rounded-full bg-blue-400 p-2` :
                        `${Width ? Width : 'w-full'} text-blue-400 border-[2px] border-blue-400 p-2  rounded-full`}`}
            >{Label}</button>
        </div>
    )
}
