import React from 'react'
import { UseFormRegister, FieldValues, FieldErrors, RegisterOptions } from 'react-hook-form';

interface Props{
    FieldName: string,
    Type? :string,
    Placeholder: string;
    Register: UseFormRegister<FieldValues>;
    Errors: FieldErrors<FieldValues>;
    Validation?: RegisterOptions;
    Disable? : boolean
}

export default function BTextBox({FieldName, Placeholder, Register, Errors, Validation, Type, Disable }: Props) {
    const errorMessage = Errors[FieldName]?.message as string | undefined;
  return (
    <div className="relative my-2">
    <textarea rows={4}
      {...Register(FieldName, Validation)}
      className={`w-full border border-gray-300 rounded-xl p-2 focus:outline-none focus:border-slate-600 ${
        Errors[FieldName] ? 'border-red-500' : ''
      }`}
      id={FieldName}
      placeholder=""
      disabled={Disable}
    ></textarea>
    <label
      htmlFor={FieldName}
      className={` text-xs absolute transition-all duration-200 ease-in-out text-gray-500 pointer-events-none ${
        Errors[FieldName] ? 'text-red-500' : ''
      }`}
    >
      {Placeholder}
    </label> 
    {Errors[FieldName] && <p className="text-xs text-red-600 mt-1">{errorMessage}</p>}
  </div>
  )
}
