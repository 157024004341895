// src/context/AuthContext.tsx
import React, { createContext, useState, ReactNode, useContext } from 'react';
import { signInUser } from '../firebaseConfig';
import { toast } from 'react-toastify';
import { readUsers } from '../_services/userreadservice';
import { UserDAO, UserState } from '../_models/user';
import { useNavigate } from 'react-router-dom';
import Spinner from './spinner';

interface AuthContextType {
    user: UserState | null;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<UserState | null>(null);
    const [isProcess, setIsProcess] = useState<boolean>(false);
    const navigate = useNavigate();
    const login = async (username: string, password: string) => {
        setIsProcess(true);
        const result = await signInUser(username, password);
        if (result.success) {
            const users = await readUsers();
            const user = users.find(f => f.emailAddress === username)

            if (user != null) {
                setUser({ Role: user.role, Name: user?.name + " " + user?.surname, PasswordChange: user.requirePasswordChange })
                navigate('/dashboard');
            }
        } else {
            toast.error('Invalid credentials');
        }
        setIsProcess(false);
    };

    const logout = () => {
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {isProcess && <Spinner/>}
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
