import { useState } from 'react'
import BInputFieldbutton from '../../_components/formfields/BInputFieldbutton';
import { readLocations } from '../../_services/storagereadservice';
import { LocationDAO, PickDAO, PickedStatus, PickItemsDAO } from '../../_models/invertory';
import { toast } from 'react-toastify';
import Spinner from '../../_components/spinner';
import { FieldValues, useForm } from 'react-hook-form';
import BInputField from '../../_components/formfields/BInputField';
import BButton, { ButtonType } from '../../_components/button';
import Modal from '../../_components/modal';
import { FaTrash } from 'react-icons/fa';
import { CreatePickEntry } from '../../_services/pickcreationservice';
import { useNavigate } from 'react-router-dom';
enum Stepper {
    Selector = 1,
    Confirm = 2,

}
export default function PickView() {

    const [currentStep, setCurrentStep] = useState<Stepper>(Stepper.Selector);
    const [locations, setLocations] = useState<LocationDAO[]>([]);
    const [isProcessing, setProcessing] = useState<boolean>(false);
    const [pickedItem, setPickedItem] = useState<PickItemsDAO>();
    const [picked, setPicked] = useState<PickItemsDAO[]>([]);
    const [viewQuantity, setViewQuantity] = useState<boolean>(false);

    const { register, handleSubmit, reset, formState: { errors } } = useForm<FieldValues>();

    const navigate = useNavigate();

    const onSearch = async (input: string) => {
        setProcessing(true);
        const result = await readLocations();
        setProcessing(false);

        if (result.length === 0) {
            toast.error('No located products');
            return;
        }

        setLocations(result.filter(f => f.code === input));
    }

    const _setPick = (row: LocationDAO) => {

        const pick = { code: row.code, quantity: 0, location: row.storage, created_date : new Date(), updated_date: new Date() } as PickItemsDAO
        setPickedItem(pick);
        setViewQuantity(true);
    }

    const onAddHandle = (data: FieldValues) => {

        let row = 0
        if (picked.length > 0) {
            row = picked.reduce((max, item) =>
                item.row > max ? item.row : max,
                picked[0].row);
        }

        const result = [...picked, { ...pickedItem, row: row + 1, quantity: data.quantity }] as PickItemsDAO[];
        setPicked(result);
        setViewQuantity(false);
        reset();
    }

    const deletePicked = (row: PickItemsDAO) => {
        const findIndex = picked.findIndex(f => f.code === row.code && f.location === row.location);

        if (findIndex !== -1) {
            picked.splice(findIndex, 1);
            setPicked([...picked]);
        }

        if (picked.length === 0) {
            setCurrentStep(Stepper.Selector);
        }

    }

    const handleCreate = async () => {

        setProcessing(true);

        const response = await CreatePickEntry({ picker: 'Default', status: PickedStatus.Created, items: picked } as PickDAO)

        setProcessing(false);

        if (!response.success) {
            toast.error(response.errorMessage);
            return;
        }

        toast.success('Pick entry created');
        navigate('/dashboard');
    }

    const QuantityView = () => (
        <form className='w-full flex flex-col gap-3' onSubmit={handleSubmit(onAddHandle)}>
            <h3>Select how many you taking</h3>
            <hr />
            <BInputField
                FieldName='quantity'
                Register={register}
                Errors={errors}
                Placeholder='How many items'
            />
            <div className='w-full flex flex-col gap-3'>
                <BButton CallBack={() => { setViewQuantity(false); reset() }} Label='Cancel' ButtonStyle={ButtonType.Secondary} />
                <BButton Submit={() => { }} Label='Save' ButtonStyle={ButtonType.Primary} />
            </div>
        </form>
    )



    const _renderView = () => {
        switch (currentStep) {
            case Stepper.Selector:
                return (
                    <div className='w-full flex-col gap-4'>
                        <BInputFieldbutton Label='Search' Placeholder='Enter part number' onSubmit={onSearch} />
                        <div className='w-full flex flex-col gap-3 my-3'>
                            {
                                locations.map((location) => (
                                    <button onClick={() => _setPick(location)} key={location.id} className='w-full flex flex-row bg-gradient-to-r from-blue-100 to-white rounded-md'>
                                        <div className='bg-blue-300 w-[50px] rounded-l-md p-2 pl-2'>
                                            {location.quantity}
                                        </div>
                                        <div className='p-1 pl-3'>
                                            <p className='text-[12px]'>{location.storage}</p>
                                            <p className='text-[10px] text-gray-500'>{location.code}</p>
                                        </div>
                                    </button>
                                ))
                            }

                        </div>
                        {picked.length > 0 && <BButton CallBack={() => setCurrentStep(Stepper.Confirm)} Label='Next' ButtonStyle={ButtonType.Primary} />}
                    </div>
                );

            case Stepper.Confirm:
                return (
                    <div className='w-full flex flex-col gap-3 mt-3'>
                        <h3>Confirmation</h3>
                        <hr />
                        {
                            picked.map((item) => (
                                <div key={item.row} className='w-full flex flex-row bg-gradient-to-r from-blue-100 to-white rounded-md'>
                                    <div className='bg-blue-300 w-[50px] rounded-l-md p-2 pl-4'>
                                        {item.quantity}
                                    </div>
                                    <div className='w-10/12 flex justify-between'>
                                        <div className='p-1 pl-3 '>
                                            <p className='text-[12px]'>Part Number <span className='text-[10px] text-gray-500'>{item.code}</span></p>
                                            <p className='text-[10px] text-gray-500'>Storage : {item.location}</p>
                                        </div>
                                        <div className='flex justify-end py-2'>
                                            <button className='text-xs rounded-full bg-gradient-to-tr from-red-400 to-white flex gap-2 py-1 px-2' onClick={() => deletePicked(item)}><FaTrash /> Delete</button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {picked.length > 0 &&
                            <div className='w-full flex flex-col gap-3'>
                                <BButton CallBack={() => setCurrentStep(Stepper.Selector)} Label='Back' ButtonStyle={ButtonType.Secondary} />
                                <BButton CallBack={handleCreate} Label='Done' ButtonStyle={ButtonType.Primary} />
                            </div>}

                    </div>
                )


        }
    }
    return (
        <div className='w-full flex justify-center'>
            {isProcessing && <Spinner />}
            {viewQuantity && <Modal Content={QuantityView()} />}
            <div className='w-full md:w-5/12 bg-white rounded-xl p-4'>
                {_renderView()}
            </div>
        </div>
    )
}
