import { collection, doc, getDoc, getDocs } from "firebase/firestore"
import { db } from "../firebaseConfig";
import { UserDAO } from "../_models/user";
import { PickDAO } from "../_models/invertory";


export const readPicks = async () => {

    const querySnap = await getDocs(collection(db, 'Picked'));
    
    const picks: PickDAO[] = querySnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
    })) as PickDAO[];

    return picks;

}

export const readPick = async (id :string) =>{
    try
    {

        const docRef = doc(db, 'Picked', id);

        const userDoc = await getDoc(docRef);

        if(!userDoc.exists()){
            return { errorMessage: 'This pick does not exist', success: false }
        }
    
        const pick : PickDAO = {
            id : userDoc.id,
            ...userDoc.data()
        } as PickDAO

        return { data : pick, success: true };

    }catch(err){
        console.error(err);
        return { errorMessage: 'Error on getting check logs', success: false }
    }
  
}