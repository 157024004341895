import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { PickDAO, PickItemsDAO } from "../_models/invertory";


export async function CreatePickEntry(data: PickDAO) {

    try {
        
        data.created_date = new Date;
        data.updated_date  = new Date;
        await addPick(data.picker,data.items);

        return { success: true };


    } catch (err) {
        return { errorMessage: 'Error on creation check logs', success: false }
    }


}

const addPick = async (
    picker: string,
    items: PickItemsDAO[]   
) => {
    try {

        const docRef = await addDoc(collection(db, 'Picked'), {
            picker,
            items
        })
        return docRef

    } catch (err) {
        console.log('error saving pick ', err);
        return null
    }

}

