import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ProductDAO } from '../../_models/product';
import { readProducts } from '../../_services/productreadservice';

export default function ProductWeb() {
    const [products, setProducts] = useState<ProductDAO[]>([]);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            const results = await readProducts();
            setProducts(results.filter(f => f.category.toLowerCase() === id?.toLowerCase()))
        }

        fetchData();
    }, [])


    return (
        <div className='w-full flex flex-col gap-4'>
            <h3 className='text-2xl'>{id?.replace("%20", " ")}</h3>
            <div className='w-full flex flex-wrap gap-4'>
                {
                    products.map((product) => (
                        <div key={product.code} className='rounded-xl bg-white h-[300px] text-black w-full sm:w-1/2 md:w-[250px]'>
                            <img src={product.imageUrl} className='w-full rounded-t-xl h-[150px]' alt="image" />
                            <div className='p-4 relative h-[140px]'>
                                <h2>{product.name}</h2>
                                <p className='text-xs text-gray-400'>{product.code}</p>
                                <div className='absolute bottom-0'>
                                    <p><span className={`${product.discount ? 'text-gray-400 line-through text-base' : 'text-lg'} mr-3`}>R {product.price}</span>
                                        {product.discount && <span className='text-lg'>R {product.discountPrice}</span>}</p>

                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>

    )
}
