import { getDocs, collection, getDoc, doc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { ProductDAO } from "../_models/product";

export const readProducts = async () => {

    const querySnap = await getDocs(collection(db, 'Products'));
    const users: ProductDAO[] = querySnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
    })) as ProductDAO[];

    return users;

}

export const readProduct = async (id :string) =>{
    try
    {

        const docRef = doc(db, 'Products', id);

        const productDoc = await getDoc(docRef);

        if(!productDoc.exists()){
            return { errorMessage: 'This product does not exist', success: false }
        }
    
        const user : ProductDAO = {
            id : productDoc.id,
            ...productDoc.data()
        } as ProductDAO

        return { data : user, success: true };

    }catch(err){
        console.error(err);
        return { errorMessage: 'Error on getting check logs', success: false }
    }
  
}

export const readProductByCode = async (partNumber :string) =>{
    try
    {

        const products = await readProducts();
    
        const product = products.find(f => f.code === partNumber);

        if(!product){
            return { errorMessage: 'This product does not exist', success: false }
        }      

        return { data : product, success: true };

    }catch(err){
        console.error(err);
        return { errorMessage: 'Error on getting check logs', success: false }
    }
  
}