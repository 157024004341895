import { collection, doc, getDoc, getDocs } from "firebase/firestore"
import { db } from "../firebaseConfig";
import { ReceiveDAO } from "../_models/invertory";


export const readReceives = async () => {

    const querySnap = await getDocs(collection(db, 'Receives'));
    const receives: ReceiveDAO[] = querySnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
    })) as ReceiveDAO[];

    return receives;

}

export const readReceive = async (id :string) =>{
    try
    {

        const docRef = doc(db, 'Receives', id);

        const receiveDoc = await getDoc(docRef);

        if(!receiveDoc.exists()){
            return { errorMessage: 'This receive does not exist', success: false }
        }
    
        const receive : ReceiveDAO = {
            id : receiveDoc.id,
            ...receiveDoc.data()
        } as ReceiveDAO

        return { data : receive, success: true };

    }catch(err){
        console.error(err);
        return { errorMessage: 'Error on getting check logs', success: false }
    }
  
}