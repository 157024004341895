import { useEffect, useState } from 'react'
import BButton, { ButtonType } from '../../_components/button'
import { FieldValues, useForm } from 'react-hook-form';
import { readProductByCode } from '../../_services/productreadservice';
import { CreateLocationStorage } from '../../_services/storagecreationservice';
import { toast } from 'react-toastify';
import { LocationDAO, ReceiveDAO } from '../../_models/invertory';
import BInputField from '../../_components/formfields/BInputField';
import BSelectField from '../../_components/formfields/BSelectField';
import { validateNonZero, validateNotEmpty } from '../../utility/validations';
import BModal from '../../_components/modal';
import { StorageDAO } from '../../_models/product';
import { readStorage } from '../../_services/settingsreadservice';
import Spinner from '../../_components/spinner';
import { readReceives } from '../../_services/receivereadservice';
import { DeleteReceive } from '../../_services/receivemanagementservice';

export default function ReceiveView() {

    const [processing, setProcessing] = useState(false);
    const [storages, setStorages] = useState<StorageDAO[]>([]);
    const [receives, setReceives] = useState<ReceiveDAO[]>([]);

    const { register, reset, handleSubmit, setValue, formState: { errors } } = useForm<FieldValues>({});

    const [pack, setPack] = useState<boolean>(false);

    useEffect(() => {
        setProcessing(true);
        fetchData();
        setProcessing(false);
    })

    const fetchData = async () => {
        const storages = await readStorage();
        const receives = await readReceives();
        setStorages(storages);
        setReceives(receives);
    }

    const handlePack = async (data: FieldValues) => {
        
        setProcessing(true);
        const productResult = await readProductByCode(data.code);

        if (!productResult.success) {
            setProcessing(false);
            toast.error(productResult.errorMessage);
            return;
        }

        const response = await CreateLocationStorage(data as LocationDAO)

        if (!response.success) {
            setProcessing(false);
            toast.error(response.errorMessage);
            return;
        }

        if(data.ref){
            setProcessing(true);
            await DeleteReceive(data.ref);
        }

        setProcessing(false);
        setPack(false)
        reset();
        toast.success('Saved');

    }

    const _setPack = (receive : ReceiveDAO) =>{

        setValue('quantity', receive.quantity);
        setValue('code', receive.code);
        setValue('ref', receive.id);        
        setPack(true);

    }

    const _renderPack = (
        <div className='w-full flex flex-col'>
            <h3>Pack Items</h3>
            <hr />
            <form className='mt-2 w-full p-2 flex flex-col gap-2' onSubmit={handleSubmit(handlePack)}>
                <BInputField FieldName='code' Register={register} Placeholder='Part Number' Errors={errors}
                    Validation={{
                        required: 'This field is required'
                    }} />
                <BInputField FieldName='quantity' Register={register} Placeholder='Quantity' Errors={errors}
                    Validation={{
                        required: 'This field is required',
                        validate: (value) => {
                            if (validateNonZero(value)) {
                                return 'This field value can not be 0 or less then zero'
                            }
                            return true
                        }
                    }} />
                <BSelectField Data={storages} FieldName='storage' Register={register} Placeholder='Select storage space' Errors={errors}
                    Validation={{
                        required: 'Select storage space',
                        validate: (value) => {
                            if (validateNotEmpty(value)) {
                                return 'Select storage space'
                            }
                            return true
                        }
                    }} />

                <BButton CallBack={() => { reset(); setPack(false) }} ButtonStyle={ButtonType.Secondary} Label='Cancel' />
                <BButton Submit={() => { }} ButtonStyle={ButtonType.Primary} Label='Pack' />
            </form>
        </div>

    )

    return (
        <div className='w-full flex flex-col gap-3'>
            {processing && <Spinner />}
            {pack && <BModal Content={_renderPack} />}
            <div className='w-full flex justify-between'>
                <h3>Received Items</h3>
                <BButton CallBack={() => setPack(true)} Label='Receive Items' ButtonStyle={ButtonType.Primary} Width='w-[150px]' />
            </div>
            <hr />
            {receives.length > 0 && <div className='w-full flex flex-col gap-3 bg-white rounded-xl p-4 justify-center'>
                <h3>Items required to be packed</h3>
                <hr/>
                {                    
                    receives.map((receive) => (
                        
                        <button onClick={() => _setPack(receive)} key={receive.id} className='w-full flex flex-row bg-gradient-to-r from-blue-100 to-white rounded-md'>
                            <div className='bg-blue-300 w-[50px] h-[60px] rounded-l-md p-2 pl-2 pt-4'>
                                {receive.quantity}
                            </div>
                            <div className='p-3 pl-3'>
                                <p className='text-[12px] text-left'>Part Number: {receive.code}</p>
                                <p className='text-[10px] text-gray-500'>{receive.reference}</p>
                            </div>
                        </button>
                    ))
                }
            </div>}
        </div>
    )
}
