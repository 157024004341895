
export interface CartDAO{
    id?: string
    name : string,
    surname : string,
    contact : string,
    emailAddress : string,
    reference : string,
    number : string,  
    cartStatus : string,
    total : Number,
    createdBy : string,
    updateBy :string,
    cartItems : CartItemsDAO[] 
}

export interface CartItemsDAO{
    description : string,
    code :string,
    price : Number,
    totalprice : Number
    quantity : Number
}



export enum CartStatus{
    Unpaid = "Unpaid",
    Paid = "Paid",
}