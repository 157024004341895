import React, { useState } from 'react'
import { FaPlusCircle } from 'react-icons/fa'

interface Parameters {
    Label: string,
    Placeholder: string
    Class?: string
    onSubmit: (value: string) => void;
}

export default function BInputFieldbutton({ Label, Placeholder, onSubmit,Class }: Parameters) {
    const [inputValue, setInputValue] = useState<string>('')

    const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    }

    const handleSubmit = () => {
        onSubmit(inputValue);
    }

    return (
        <div className={`${Class ? Class : 'flex flex-row gap-2 mt-2'}`} >
            <div className='w-7/12'>
                <input type="text" onChange={onTextChange} className='w-full rounded-full border border-gray p-[5.5px] text-sm' placeholder={Placeholder} />
            </div>
            <div className='w-5/12'>
                <button onClick={handleSubmit} className='flex w-full  gap-2 text-xs rounded-full bg-gradient-to-tr from-blue-400 to-white p-2'><FaPlusCircle /> {Label} </button>
            </div>
        </div>
    )
}
