
import React, { useState } from 'react'
import BButton, { ButtonType } from '../../_components/button'
import { FieldValues, useForm } from 'react-hook-form'
import BInputField from '../../_components/formfields/BInputField';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../_components/authProvider';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import { toast } from 'react-toastify';
import Spinner from '../../_components/spinner';


export default function ResetPassword() {

    const { register, handleSubmit, reset, formState: { errors } } = useForm<FieldValues>();
    const [isLoading, setLoading] = useState<boolean>(false)

    const navigate = useNavigate();

    const handleSignIn = async (data: FieldValues) => {
        try {
            setLoading(true);
            await sendPasswordResetEmail(auth, data.emailAddress);
            toast.success('Email send');
            setLoading(false);
            navigate('/sign-in');

        } catch (err) {
            toast.error('Failed to reset password')
        }
    }



    return (
        <div className='w-full flex justify-center p-4'>
            {isLoading && <Spinner/>}
            <div className='w-full mt-[180px] md:mt-[100px] md:w-4/12 rounded-xl shadow-md p-3 text-center'>
                <form onSubmit={handleSubmit(handleSignIn)} className='w-full flex flex-col gap-4'>
                    <h4 className='text-xl'>Reset Password</h4>
                    <BInputField
                        FieldName='emailAddress'
                        Placeholder='Email Address'
                        Register={register}
                        Errors={errors}
                    />

                    <BButton Submit={() => { }} ButtonStyle={ButtonType.Primary} Label={'Submit'} />
                    <button type='button' onClick={() => navigate('/sign-in')} className='text-sm'>Cancel</button>

                </form>
            </div>
        </div>
    )
}
