import { collection, doc, getDoc, getDocs } from "firebase/firestore"
import { db } from "../firebaseConfig";
import { UserDAO } from "../_models/user";
import { CartDAO } from "../_models/cart";


export const readInvoices = async () => {

    const querySnap = await getDocs(collection(db, 'Invoices'));
    const invoices: CartDAO[] = querySnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
    })) as CartDAO[];

    return invoices;

}

export const readInvoice = async (id :string) =>{
    try
    {

        const docRef = doc(db, 'Invoices', id);

        const userDoc = await getDoc(docRef);

        if(!userDoc.exists()){
            return { errorMessage: 'This invoice does not exist', success: false }
        }
    
        const invoice : CartDAO = {
            id : userDoc.id,
            ...userDoc.data()
        } as CartDAO

        return { data : invoice, success: true };

    }catch(err){
        console.error(err);
        return { errorMessage: 'Error on getting check logs', success: false }
    }
  
}

export const ReadInvoiceByRef = async (ref :string) =>{
    try
    {

        const results = await readInvoices();

        const invoice = results.find(f => f.reference === ref)

        if(!invoice){
            return { errorMessage: 'This invoice does not exist', success: false }
        }    

        return { data : invoice, success: true };

    }catch(err){
        console.error(err);
        return { errorMessage: 'Error on getting check logs', success: false }
    }
  
}

