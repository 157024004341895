import { addDoc, collection } from "firebase/firestore";
import { BrandDAO, CategoryDAO, StorageDAO } from "../_models/product";
import { readBrands } from "./settingsreadservice";
import { db } from "../firebaseConfig";

export async function CreateBrand(data: BrandDAO) {

    try {

        //check if email exists
        const users = await readBrands();

        if (users.find(x => x.description.toLowerCase() === data.description.toLowerCase())) {

            return { errorMessage: 'This brand exists', success: false }
        }

        await addBrand(data.description);

        return { success: true };


    } catch (err) {
        return { errorMessage: 'Error on creation check logs', success: false }
    }


}

export async function CreateCategory(data: CategoryDAO) {

    try {

        //check if email exists
        const users = await readBrands();

        if (users.find(x => x.description.toLowerCase() === data.description.toLowerCase())) {

            return { errorMessage: 'This brand exists', success: false }
        }

        await addCategory(data.description);

        return { success: true };


    } catch (err) {
        return { errorMessage: 'Error on creation check logs', success: false }
    }


}

export async function CreateStorage(data: StorageDAO) {

    try {

        //check if email exists
        const users = await readBrands();

        if (users.find(x => x.description.toLowerCase() === data.description.toLowerCase())) {

            return { errorMessage: 'This Storage name exists', success: false }
        }

        await addStorage(data.description);

        return { success: true };


    } catch (err) {
        return { errorMessage: 'Error on creation check logs', success: false }
    }


}


const addBrand = async (
    description: string
    
) => {
    try {

        const docRef = await addDoc(collection(db, 'Brands'), {
            description
        })
        return docRef

    } catch (err) {
        console.log('error saving brand ', err);
        return null
    }

}

const addCategory = async (
    description: string
    
) => {
    try {

        const docRef = await addDoc(collection(db, 'Categories'), {
            description
        })
        return docRef

    } catch (err) {
        console.log('error saving category ', err);
        return null
    }

}

const addStorage = async (
    description: string
    
) => {
    try {

        const docRef = await addDoc(collection(db, 'Storages'), {
            description
        })
        return docRef

    } catch (err) {
        console.log('error saving storage ', err);
        return null
    }

}

