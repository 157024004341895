import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { ReceiveDAO } from "../_models/invertory";


export async function CreateReceive(data: ReceiveDAO) {

    try {

        
        await addReceive(data.code,data.reference,data.quantity,new Date, new Date);

        return { success: true };


    } catch (err) {
        return { errorMessage: 'Error on creation check logs', success: false }
    }


}

const addReceive = async (
    code: string,
    reference: string,
    quantity: number,
    created_date: Date,
    updated_date: Date
) => {
    try {

        const docRef = await addDoc(collection(db, 'Receives'), {
            code,
            reference,
            quantity,
            created_date,
            updated_date
        })
        return docRef

    } catch (err) {
        console.log('error saving receive ', err);
        return null
    }

}

