import { Timestamp } from "firebase/firestore"
import { ProductDAO } from "./product"

export interface LocationDAO{
    id?: string
    code : string
    quantity : number
    storage : string
    created_date : Date
    updated_date : Date
   
}

export interface PickDAO{
    id?: string,
    picker: string,
    status: string
    items : PickItemsDAO[]
    created_date : Date
    updated_date : Date
}

export interface PickItemsDAO{
    row: number,
    code: string,
    quantity : number
    location : string
    created_date : Date
    updated_date : Date
}

export enum PickedStatus{
    Created = "Created",
    Processed = "Processed",
    Deleted = "Deleted"
}

export interface ReceiveDAO{
    id?: string,
    code :string,
    reference: string,
    quantity:number,
    created_date : Date
    updated_date : Date
}