import { useEffect, useState } from 'react'
import { IoDocuments, IoHome } from "react-icons/io5";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { LiaSignOutAltSolid } from "react-icons/lia";
import { FaRegUserCircle, FaUsers } from "react-icons/fa";
import { AiFillMessage, AiOutlineProduct } from "react-icons/ai";
import { FaGear } from "react-icons/fa6";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth} from '../../firebaseConfig';
import { signOut, updatePassword } from 'firebase/auth';
import { useAuth } from '../authProvider';
import { FieldValues, get, useForm } from 'react-hook-form';
import BInputField from '../formfields/BInputField';
import Modal from '../modal';
import BButton, { ButtonType } from '../button';
import { readUser, readUsers } from '../../_services/userreadservice';
import { UpdateUser } from '../../_services/usermanagementservice';

export default function Securelayout() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm<FieldValues>();
    const user = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user.user == null) {
            navigate('/sign-in');
        }
    })
    const { logout } = useAuth();

    const password = watch('password');

    const isMobile = typeof window !== 'undefined' && window.innerWidth < 620;

    const onHandleSubmit = async (data: FieldValues) => {
        const currentUser = auth.currentUser;
        if (currentUser != null) {
            try {
                await updatePassword(currentUser, data.password)
                await updateUser(currentUser.email??'');
                toast.success('password changed successfully');
                handleSignOut();
            } catch (err) {
                toast.error('Failed to update password');
            }
        }

    }

    const updateUser = async (email : string) =>{
        const results = await readUsers();
        const result = results.find(f => f.emailAddress == email);
        if(result){
            result.requirePasswordChange = false;
            await UpdateUser(result, result.id??'');
        }
    }

    const _renderPasswordView = (
        <form onSubmit={handleSubmit(onHandleSubmit)} className="flex flex-col gap-2">
            <h3>Change Password</h3>
            <hr/>
            <BInputField FieldName='password' Placeholder='New Password' Register={register} Errors={errors}
                Validation={{
                    required: 'This field is required',
                    minLength: {
                        value: 7,
                        message: 'Password must be at least 7 characters long'
                    }
                }}

            />
            <BInputField FieldName='confirmPassword' Placeholder='Confirm Password' Register={register} Errors={errors}
                Validation={{
                    required: 'This field is required',
                    validate: (value: string) => {
                        return value === password || 'Passwords do not match';
                    }
                }} />
            <BButton Submit={() =>{}} ButtonStyle={ButtonType.Primary} Label='Save'/>
        </form>
    )

    const handleSignOut = () => {
        logout();
        signOut(auth)
            .then(() => { navigate('/sign-in') })
            .catch((error) => console.error(error));
    };

    const navigationbar = () => {

        if (isMobile) {
            return (
                <div className='flex flex-row fixed bottom-0 w-full bg-white h-[65px] justify-center z-40'>
                    {!['floor'].includes(user.user?.Role.toLowerCase() ?? '') && <div className='w-2/12 p-4'>
                        <Link to={'/products'}><AiOutlineProduct size={30} /></Link>
                    </div>}
                    {!['floor'].includes(user.user?.Role.toLowerCase() ?? '') && <div className='w-2/12 p-4'>
                        <Link to={'/sales'}><IoDocuments size={30} /></Link>
                    </div>}
                    <div className='w-3/12 text-center border-[4px] border-slate-100 rounded-full -mt-5'>
                        <div className='w-full h-full rounded-full bg-white align-middle flex justify-center pt-3'>
                            <Link to={'/dashboard'}><IoHome className='text-blue-400' size={45} /></Link>
                        </div>
                    </div>
                    {!['floor'].includes(user.user?.Role.toLowerCase() ?? '') && <div className='w-2/12 p-4'>

                        <Link to={'/settings'}> <FaGear size={30} /></Link>
                    </div>}
                    {!['floor'].includes(user.user?.Role.toLowerCase() ?? '') && <div className='w-2/12 p-4'>
                        <Link to={'/users'}> <FaUsers size={30} /></Link>

                    </div>}
                </div>
            )
        }

        return (
            <div className='fixed flex flex-col h-full w-[200px] bg-gradient-to-br from-blue-400 to-white z-30'>
                <div className='w-full bg-gray-700'>
                    <img src='/basLogo.png' className='h-[200px]' />
                </div>
                <div className='flex flex-col'>
                    <Link to={'/dashboard'} className='flex gap-2 p-2 hover:bg-blue-300 hover:text-white text-xs hover:rounded-l-xl'><IoHome size={15} /> Home</Link>
                    {['admin', 'superadmin', 'user'].includes(user.user?.Role.toLowerCase() ?? '') && <Link to={'/products'} className='flex gap-2 p-2 hover:bg-blue-300 hover:text-white text-xs hover:rounded-l-xl'><AiOutlineProduct size={15} />Products</Link>}
                    {['admin', 'superadmin'].includes(user.user?.Role.toLowerCase() ?? '') && <Link to={'/users'} className='flex gap-2 p-2 hover:bg-blue-300 hover:text-white text-xs hover:rounded-l-xl'><FaUsers size={15} />Users</Link>}
                    {['admin', 'superadmin'].includes(user.user?.Role.toLowerCase() ?? '') && <Link to={'/settings'} className='flex gap-2 p-2 hover:bg-blue-300 hover:text-white text-xs hover:rounded-l-xl'><FaGear size={15} />Settings</Link>}
                </div>
            </div>
        )
    }

    const topNavigationBar = (
        <div className='fixed h-[65px] w-full border-b border-blue-400 bg-slate-100 z-20'>
            <div className='flex justify-end p-5 gap-4 flex-row'>
                <button><AiFillMessage className='text-blue-400' size={25} /></button>
                <button><FaRegUserCircle className='text-blue-400' size={25} /></button>
                <button onClick={() => handleSignOut()}><LiaSignOutAltSolid className='text-blue-400' size={25} /></button>
            </div>
        </div>
    )





    return (
        <div className='bg-slate-100 w-full min-h-screen'>
            {navigationbar()}
            {topNavigationBar}
            {user.user?.PasswordChange && <Modal Content={_renderPasswordView}/>}
            <main className='md:pl-[200px]'>
                <div className='w-full pt-[74px] px-4'>
                    <Outlet />
                    <ToastContainer />
                </div>
            </main>
        </div>
    )
}
