import { GiCardPickup } from "react-icons/gi";
import { FaFileInvoice } from "react-icons/fa6";
import { useEffect, useState } from "react";
import BButton, { ButtonType } from "../../_components/button";
import { FaEdit, FaFilter, FaPlusCircle, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DeletePicked } from "../../_services/pickmanagementservice";
import { toast } from "react-toastify";
import { ReadInvoiceByRef, readInvoices } from "../../_services/invoicereadservice";
import { CartDAO } from "../../_models/cart";
import { PickDAO, PickedStatus, ReceiveDAO } from "../../_models/invertory";
import { readPicks } from "../../_services/pickreadservice";
import BTableData from "../../_components/btable";
import BTablecolumn from "../../_components/btablecolumns";
import { Datatype } from "../../_models/table";
import { DeleteInvoice } from "../../_services/invoicemanagementservice";
import BModal from "../../_components/modal";
import Spinner from "../../_components/spinner";
import { CreateReceive } from "../../_services/receivecreationservice";
import { DeleteReceive } from "../../_services/receivemanagementservice";

enum ViewOption {
  None = 0,
  PickedItem = 1,
  Invoices = 2
}

export default function SaleView() {
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState<ViewOption>(ViewOption.None);
  const [invoices, setInvoices] = useState<CartDAO[]>([]);
  const [invoice, setInvoice] = useState<CartDAO>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [picks, setPicks] = useState<PickDAO[]>([]);

  const isMobile = window.innerWidth < 640
  const navigate = useNavigate();

  useEffect(() => {
    setProcessing(true);
    if (!isMobile) {
      setCurrentView(ViewOption.Invoices)
    }
    fetchData();
    setProcessing(false);
  }, []);

  const fetchData = async () => {
   
    const invoiceResults = await readInvoices();
    const pickResults = await readPicks();
    setInvoices(invoiceResults);
    setPicks(pickResults.filter(f => f.status !== PickedStatus.Processed));
  
  }

  const deleteInvoice = async (data: CartDAO) => {
    setInvoice(data);   
    setDeleteModal(true);
  }

  const handleDelete = async () => {

    setProcessing(true);

    const response = await DeleteInvoice(invoice?.id || '');

    for(const item of invoice?.cartItems ?? []){

      await CreateReceive({code: item.code, reference: invoice?.reference, quantity : item.quantity} as ReceiveDAO);
    }

    if (!response.success) {
      toast.error(response.errorMessage);
      setProcessing(false);
      return;
    }

    setDeleteModal(false);
    const data = await readInvoices();
    setInvoices(data);

    setProcessing(false);

  }

  const deletePicked = async (id: string) => {
    setProcessing(true);

    const response = await DeletePicked(id);   

    if (!response.success) {
      setProcessing(false);
      toast.error(response.errorMessage);
      return;
    }

    toast.success('Pick deleted')
    if (isMobile) {
      setCurrentView(ViewOption.None);
    }
    
    fetchData();
    setProcessing(false);
  }

  const PickerButton = (data: any) => (
    <div className='flex flex-row gap-2'>
      <button className='text-xs rounded-full bg-gradient-to-tr from-green-300 to-white flex gap-2 py-1 px-2' onClick={() => navigate(`/invoice/${data.id}`)}><FaPlusCircle /> New Invoice </button>
      <button className='text-xs rounded-full bg-gradient-to-tr from-red-400 to-white flex gap-2 py-1 px-2' onClick={() => deletePicked(data.id ?? '')}><FaTrash /> Delete </button>
    </div>
  )

  const InvoiceButton = (data: CartDAO) => (
    <div className='flex flex-row gap-2'>
      <button className='text-xs rounded-full bg-gradient-to-tr from-red-400 to-white flex gap-2 py-1 px-2' onClick={() => deleteInvoice(data)}><FaTrash /> Delete </button>
      <button className='text-xs rounded-full bg-gradient-to-tr from-green-300 to-white flex gap-2 py-1 px-2' onClick={() => navigate(`/invoice/${data.id}`)}><FaEdit /> Edit </button>
    </div>
  )

  const deleteContent = (
    <div className='w-full flex flex-col justify-center text-center'>
      <h3 className='mt-3'>Are you sure you want to delete?</h3><br />
      <p className='text-sm'>Please not this info may not be recovered</p>
      <div className='flex flex-row gap-2 w-full justify-center mt-9'>
        <button className='text-xs w-[80px] rounded-full bg-gradient-to-tr from-blue-400 to-white flex gap-2 py-1 px-2' onClick={() => handleDelete()}><FaTrash /> Yes </button>
        <button className='text-xs w-[80px] rounded-full bg-gradient-to-tr from-red-400 to-white flex gap-2 py-1 px-2' onClick={() => setDeleteModal(false)}><FaEdit /> No </button>
      </div>
    </div>
  )



  const MobileView = () => {

    switch (currentView) {
      case ViewOption.None:
        return (
          <div className="flex flex-col gap-3">
            <h3>Sale Administration</h3>
            <hr />
            <button onClick={() => setCurrentView(ViewOption.PickedItem)} className='w-full flex flex-row bg-gradient-to-r from-blue-100 to-white rounded-md'>
              <div className='bg-blue-300 w-[50px] h-[60px] rounded-l-md p-2 pl-2 pt-3'>
                <GiCardPickup size={35} />
              </div>
              <div className='p-3 pl-3'>
                <p className='text-[14px] text-left'>View picked</p>
                <p className='text-[10px] text-gray-500'>Convert the picked list into an invoice</p>
              </div>
            </button>
            <button onClick={() => setCurrentView(ViewOption.Invoices)} className='w-full flex flex-row bg-gradient-to-r from-blue-100 to-white rounded-md'>
              <div className='bg-blue-300 w-[50px] h-[60px] rounded-l-md p-2 pl-2 pt-3'>
                <FaFileInvoice size={35} />
              </div>
              <div className='p-3 pl-3'>
                <p className='text-[14px] text-left'>Invoices</p>
                <p className='text-[10px] text-gray-500'>View the history of invoices</p>
              </div>
            </button>
          </div>
        )

      case ViewOption.Invoices:
        return (
          <div className="w-full flex flex-col gap-3">
            <h3>Invoices</h3>
            <hr />
            {
              invoices.map((invoice) => (
                <button  onClick={() => navigate(`/invoice/${invoice.id}`)} key={invoice.id} className='w-full flex justify-between bg-gradient-to-r from-blue-100 to-white rounded-md'>
                  <div className='p-3 pl-3'>
                    <p className='text-[14px] text-left'>{invoice.number}</p>
                    <p className='text-[10px] text-gray-500'>{invoice.name} {invoice.surname} <span className="bg-green-200 text-[12px] p-1 rounded-full">{invoice.cartStatus}</span></p>
                  </div>
                  <div className="text-right p-3 pr-3">
                    <p className='text-[14px] '>R {invoice.total.toString()}</p>
                    <p className='text-[10px] text-gray-500'>Select</p>
                  </div>
                </button>
              ))
            }

            <div className="w-full">
              <BButton CallBack={() => setCurrentView(ViewOption.None)} Label='Back' ButtonStyle={ButtonType.Primary} />
            </div>
          </div>
        )

      case ViewOption.PickedItem:
        return (
          <div className="w-full flex flex-col gap-3">
            <h3>Picked Items</h3>
            <hr />
            {
              picks.map((pick) => (
                <div key={pick.id} onClick={() => setCurrentView(ViewOption.PickedItem)} className='w-full flex flex-row bg-gradient-to-r from-blue-100 to-white rounded-md'>
                  <div className="w-full flex justify-between">
                    <div className='p-3 pl-3'>
                      <p className='text-[14px] text-left'> Picked: {pick.picker}</p>
                      <p className='text-[10px] text-gray-500 text-left'> {pick.id}</p>
                    </div>
                    <div className='flex flex-row gap-2 justify-end py-4'>
                      <button onClick={() => navigate(`/invoice/${pick.id}`)} className='text-xs rounded-full bg-gradient-to-tr from-green-300 to-white flex gap-2 pt-2 px-2' ><FaPlusCircle /> New Invoice</button>
                      <button onClick={() => deletePicked(pick.id ?? '')} className='text-xs rounded-full bg-gradient-to-tr from-red-300 to-white flex gap-2 pt-2 px-2' ><FaTrash /> Delete</button>
                    </div>
                  </div>
                </div>
              ))
            }

            <div className="w-full">
              <BButton CallBack={() => setCurrentView(ViewOption.None)} Label='Back' ButtonStyle={ButtonType.Primary} />
            </div>
          </div>
        )
    }




  }

  const WebView = () => {

    let Content: JSX.Element = <></>;

    switch (currentView) {
      case ViewOption.PickedItem:
        Content = (
          <div className="w-full">
            <h3>Picked Items</h3>
            <hr />
            <div className='flex w-full justify-between my-3'>
              <div className='flex flex-row gap-4 text-blue-400'><button><FaFilter /></button><button><FaSearch /></button></div>

            </div>
            <BTableData dataSource={picks} actions={PickerButton}>
              <BTablecolumn column='id' label='Reference Number' />
              <BTablecolumn column='picker' label='Picked By' />
            </BTableData>
          </div>
        )
        break;
      case ViewOption.Invoices:
        Content = (
          <div className="w-full">
            <h3>Invoices</h3>
            <hr />
            <div className='flex w-full justify-between my-3'>
              <div className='flex flex-row gap-4 text-blue-400'><button><FaFilter /></button><button><FaSearch /></button></div>

            </div>
            <BTableData dataSource={invoices} actions={InvoiceButton}>
              <BTablecolumn column='name' label='Name' />
              <BTablecolumn column='surname' label='Surname' />
              <BTablecolumn column='contact' label='Contact' />
              <BTablecolumn column='emailAddress' label='Email Address' />
              <BTablecolumn column='total' label='Total Amount' type={Datatype.Currency} />
              <BTablecolumn column='cartStatus' label='Status' />
            </BTableData>
          </div>
        )
        break;
    }

    return (
      <div className="w-full flex flex-col gap-3 mt-4">
        <div className="w-full flex flex-row gap-3 text-slate-500">
          <button className="border-r-2 border-blue-300 pr-3" onClick={() => setCurrentView(ViewOption.Invoices)}>Invoices</button>
          <button className="border-r-2 border-blue-300 pr-3" onClick={() => setCurrentView(ViewOption.PickedItem)}>Picked View</button>
        </div>
        {Content}
      </div>
    )
  }

  return (
    <div className='w-full flex flex-col gap-3'>
      {deleteModal && <BModal Content={deleteContent}/>}
      {isProcessing && <Spinner/>}
      {isMobile && MobileView()}
      {!isMobile && WebView()}
    </div>
  )
}
