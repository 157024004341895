import { deleteDoc, doc, updateDoc } from "firebase/firestore";

import { db } from "../firebaseConfig";


export async function UpdateUser(data : any){
    const docRef = doc(db, 'Users', data.id);
}

const updateUser = async (docRef : any, payload : any) => {
    
    return await updateDoc(docRef, payload);
}

export const DeleteBrand = async (id: string) => {

    try
    {
        const docRef = doc(db, 'Brands', id);
        
        await deleteDoc(docRef);

        return { success: true };

    }catch(err)
    {
        console.log(err);
        return { errorMessage: 'Error on deleting check logs', success: false }
    }
   
}

export const DeleteCategory = async (id: string) => {

    try
    {
        const docRef = doc(db, 'Categories', id);
        
        await deleteDoc(docRef);

        return { success: true };

    }catch(err)
    {
        console.log(err);
        return { errorMessage: 'Error on deleting check logs', success: false }
    }
   
}

export const DeleteStorage = async (id: string) => {

    try
    {
        const docRef = doc(db, 'Storages', id);
        
        await deleteDoc(docRef);

        return { success: true };

    }catch(err)
    {
        console.log(err);
        return { errorMessage: 'Error on deleting check logs', success: false }
    }
   
}

