import React from 'react'
import { ProductDAO, SettingStat } from '../_models/product'
import { useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { spawn } from 'child_process';

interface Parameters {
    Products: ProductDAO[];
    Header: string
}

export default function BCarsouselList({ Products, Header }: Parameters) {
    const navigate = useNavigate();
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
    return (
        <div className='w-full m-auto flex flex-col mt-3 px-5'>
            <div className='flex flex-row w-full'>
                <div className='w-full md:w-1/2'>
                    {Header}
                </div>
                <div className='w-full md:w-1/2 flex justify-end'>
                    <button onClick={() => navigate(`/products/${Header}`)} className='flex w-[150px] gap-2 text-xs rounded-full bg-gradient-to-tr from-blue-400 to-white p-2 justify-center'>View All</button>
                </div>
            </div>
            <div className='mt-4'>
                <Slider {...settings}>
                    {
                        Products.map((product) => (
                            <div key={product.code} className='rounded-xl bg-white h-[300px] text-black w-full md:w-[250px]'>
                                <img src={product.imageUrl} className='w-full rounded-t-xl h-[150px]' alt="image" />
                                <div className='p-4 relative h-[140px]'>
                                    <h2>{product.name}</h2>
                                    <p className='text-xs text-gray-400'>{product.code}</p>
                                    <div className='absolute bottom-0'>
                                        <p><span className={`${product.discount ? 'text-gray-400 line-through text-base' : 'text-lg'} mr-3`}>R {product.price}</span>
                                            {product.discount && <span className='text-lg'>R {product.discountPrice}</span>}</p>

                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </div>
        </div>
    )
}
