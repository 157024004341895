import { addDoc, collection } from "firebase/firestore";
import { db, signUpUser } from "../firebaseConfig";
import { UserDAO } from "../_models/user";
import { readUsers } from "./userreadservice";


export async function CreateUser(data: UserDAO) {

    try {

        const users = await readUsers();

        if (users.find(x => x.emailAddress === data.emailAddress)) {

            return { errorMessage: 'This email address exists', success: false }
        }

        await addUser(data.name,data.surname,data.emailAddress,data.role,true);

        await signUpUser(data.emailAddress,'P@ssw0rd@123')

        return { success: true };


    } catch (err) {
        return { errorMessage: 'Error on creation check logs', success: false }
    }


}

const addUser = async (
    name: string,
    surname: string,
    emailAddress: string,
    role: string,
    requirePasswordChange : boolean,
) => {
    try {

        const docRef = await addDoc(collection(db, 'Users'), {
            name,
            surname,
            emailAddress,
            role,
            requirePasswordChange
        })
        return docRef

    } catch (err) {
        console.log('error saving user ', err);
        return null
    }

}

