import React from 'react'
import { Rings, RotatingLines } from 'react-loader-spinner'

export default function Spinner() {
    return (
        <div className='fixed inset-0 backdrop-blur-md bg-blue-200 flex justify-center z-50 bg-opacity-50 pt-[200px]'>
            <Rings
                visible={true}
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="rings-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )
}
