import React from 'react'
import { UseFormRegister, FieldValues, FieldErrors, RegisterOptions } from 'react-hook-form';

interface Props {
  FieldName: string,
  Placeholder: string;
  Width?: string;
  Register: UseFormRegister<FieldValues>;
  Errors: FieldErrors<FieldValues>;
  Validation?: RegisterOptions;
  Disable?: boolean
  RadioOptions: string[];
}

export default function BRadioButton({ FieldName, Placeholder, Register, Errors, Validation, RadioOptions, Disable }: Props) {
  const errorMessage = Errors[FieldName]?.message as string | undefined;
  return (
    <div className={'relative flex flex-row gap-2 my-2 pt-2'}>
      {
        RadioOptions.map((radio) => (
          <div key={radio}>
            <input type="radio" id="" value={radio} {...Register(FieldName, Validation)} /><span className='px-2 text-xs'>{radio}</span>
          </div>

        ))
      }
      {Errors[FieldName] && <p className="text-xs text-red-600 mt-1">{errorMessage}</p>}
    </div>
  )
}
