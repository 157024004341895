import { deleteDoc, doc, updateDoc } from "firebase/firestore";

import { db } from "../firebaseConfig";


export async function UpdateProduct(data : any, id : string){
    try
    {
        const docRef = doc(db, 'Products', id)
        delete data.id;
        await updateProduct(docRef, data);
        return {success: true}

    }catch(error)
    {
        console.log(error);
        return { errorMessage: 'Error on update check logs', success: false }
    }
}

const updateProduct = async (docRef : any, payload : any) => {
    return await updateDoc(docRef, payload);
}

export const DeleteProduct = async (id: string) => {

    try
    {
        const docRef = doc(db, 'Products', id);
        
        await deleteDoc(docRef);

        return { success: true };

    }catch(err)
    {
        console.log(err);
        return { errorMessage: 'Error on deleting check logs', success: false }
    }


   
}

