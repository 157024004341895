import React, { useEffect, useState } from 'react'
import { ProductDAO, SettingStat } from '../_models/product'
import { useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { readProducts } from '../_services/productreadservice';


export default function BAutoSlider() {
    const [products, setProducts] = useState<ProductDAO[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            const results = await readProducts();
            setProducts(results);
        }

        fetchData();

    }, [])
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='w-full m-auto flex flex-col mt-3 px-5'>
            <div className='mt-4'>
                <Slider {...settings}>
                    {
                        products.map((product) => (
                            <div key={product.code} className='rounded-full bg-white h-[200px] text-black w-full '>
                                <img src={product.imageUrl} className='w-full rounded-full h-[200px]' alt="image" />
                            </div>
                        ))
                    }
                </Slider>
            </div>
        </div>
    )
}
