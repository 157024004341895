import { collection, doc, getDoc, getDocs } from "firebase/firestore"
import { db } from "../firebaseConfig";
import { UserDAO } from "../_models/user";
import { LocationDAO } from "../_models/invertory";


export const readLocations = async () => {

    const querySnap = await getDocs(collection(db, 'Locations'));
    const users: LocationDAO[] = querySnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
    })) as LocationDAO[];

    return users;

}

export const readlocation = async (id :string) =>{
    try
    {

        const docRef = doc(db, 'Locations', id);

        const userDoc = await getDoc(docRef);

        if(!userDoc.exists()){
            return { errorMessage: 'This user does not exist', success: false }
        }
    
        const location : LocationDAO = {
            id : userDoc.id,
            ...userDoc.data()
        } as LocationDAO

        return { data : location, success: true };

    }catch(err){
        console.error(err);
        return { errorMessage: 'Error on getting check logs', success: false }
    }
  
}

export const ItemLocations = async (partNumber : string)=> {
    const querySnap = await getDocs(collection(db, 'Locations'));
    const locatedItems: LocationDAO[] = querySnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
    })) as LocationDAO[];

    const items = locatedItems.filter(f => f.code === partNumber) as LocationDAO[];

    return items
}