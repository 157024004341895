import { getDocs, collection } from "firebase/firestore";
import { BrandDAO, CategoryDAO, StorageDAO } from "../_models/product";
import { db } from "../firebaseConfig";

export const readCategories = async () => {

    const querySnap = await getDocs(collection(db, 'Categories'));
    const categories: CategoryDAO[] = querySnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
    })) as CategoryDAO[];

    return categories;

}

export const readBrands = async () => {

    const querySnap = await getDocs(collection(db, 'Brands'));
    const brands: BrandDAO[] = querySnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
    })) as CategoryDAO[];

    return brands;

}

export const readStorage = async () => {

    const querySnap = await getDocs(collection(db, 'Storages'));
    const storages: StorageDAO[] = querySnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
    })) as StorageDAO[];

    return storages;

}