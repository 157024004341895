import React from 'react'
import { FaHandsHoldingCircle } from 'react-icons/fa6'
import { MdOutlinePersonalInjury } from 'react-icons/md'
import { RiTimeZoneFill } from 'react-icons/ri'
import { TbSocial } from 'react-icons/tb'
import BAutoSlider from '../../_components/autoslider'

export default function AboutView() {
    return (
        <div className='w-full flex flex-col'>
            <h2 className='text-5xl text-slate-400 md:pl-4'>About us</h2>
            <div className='w-full flex flex-col md:flex-row rounded-xl shadow-md p-4 md:p-6 bg-transparent -mt-4 gap-4'>
                <div className='w-full md:w-1/2 text-sm'>
                    <p>Welcome to Baspares, your trusted partner in high-quality Mercedes-Benz auto spares. With years of experience in the automotive industry, we specialize in providing genuine and aftermarket parts for all Mercedes-Benz models, ensuring that your vehicle performs at its best.</p><br/>
                    <p>At Baspares, we understand the importance of maintaining the luxury, performance, and precision engineering that Mercedes-Benz is known for. That's why we offer a comprehensive selection of parts, from engine components and transmission systems to exterior body parts and accessories. Whether you're a car enthusiast, a mechanic, or a workshop, we are committed to delivering the right parts to meet your needs.</p>
                </div>
                <div className='w-full md:w-1/2 flex flex-wrap'>
                    <img src="CLA.jpg" alt="" className='rounded-2xl w-full md:w-1/2 p-2'/>
                    <img src="CLAWheel.jpg" alt="" className='rounded-2xl w-full md:w-1/2 h-[220px] p-2'/>
                </div>
            </div>
            <BAutoSlider/>
        </div>
    )
}
