
export const validateNonZero = (Value: any) : boolean => {
    if(isNaN(Value))
        return true

    if(parseInt(Value) <= 0)
        return true

    return false
}

export const validateNotEmpty = (value: any) : boolean => {
    
    if(value === "0")
        return true;    

    return false
}

export const validateNumberNonZero = (Value: any) : boolean => {
    if(isNaN(Value))
        return true

    if(parseInt(Value) < 0)
        return true

    return false
}

export const validateNumber = (Value: any) : boolean => {
    if(isNaN(Value))
        return false
    
    return true
}

export const validatePhoneNumber = (phoneNumber : string) : boolean =>{

    if(!phoneNumber.startsWith('+'))
        return true;

    if(phoneNumber.startsWith('+0'))
        return true;
    
    return false 
}