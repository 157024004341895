
import React from 'react'
import BButton, { ButtonType } from '../../_components/button'
import { FieldValues, useForm } from 'react-hook-form'
import BInputField from '../../_components/formfields/BInputField';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../_components/authProvider';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth} from '../../firebaseConfig';


export default function LoginPage() {

    const { register, handleSubmit, reset, formState: { errors } } = useForm<FieldValues>();

    const navigate = useNavigate();

    const {login} = useAuth();


    const handleSignIn = async (data: FieldValues) => {
      await login(data.emailAddress, data.password);
    }



    return (
       <div className='w-full flex justify-center p-4'>
            <div className='w-full mt-[180px] md:mt-[100px] md:w-4/12 rounded-xl shadow-md p-3 text-center'>
                <form onSubmit={handleSubmit(handleSignIn)} className='w-full flex flex-col gap-4'>
                    <h4 className='text-xl'>Sign In</h4>
                    <BInputField
                        FieldName='emailAddress'
                        Placeholder='Email Address'
                        Register={register}
                        Errors={errors}
                    />
                     <BInputField
                        FieldName='password'
                        Placeholder='Password'
                        Type='password'
                        Register={register}
                        Errors={errors}
                    />
                    <BButton Submit={() => {}} ButtonStyle={ButtonType.Primary} Label={'Submit'} />
                    <button type='button' onClick={() => navigate('/forgotpassword')} className='text-sm'>Forgot Password</button>
                </form>
            </div>
        </div>
    )
}
