import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { CartDAO, CartItemsDAO, CartStatus } from "../_models/cart";
import { readPick } from "./pickreadservice";
import { PickedStatus, PickItemsDAO } from "../_models/invertory";
import { UpdatePick } from "./pickmanagementservice";
import { readStorage } from "./settingsreadservice";
import { readLocations } from "./storagereadservice";
import { UpdateLocation } from "./storagemanageservice";
import { readProductByCode } from "./productreadservice";
import { UpdateProduct } from "./productmanagementservice";


export async function CreateInvoice(data: CartDAO) {

    try {           

        await addInvoice(
            data.name,
            data.surname,
            data.contact,
            data.emailAddress,
            data.reference,
            generateRandomNumber(),
            CartStatus.Paid,
            data.total,
            'default',
            'default',
            data.cartItems
        );

        //get the picked 
        const picked = await readPick(data.reference);
        //update it
        if(!picked.data){
           return {errorMessage: 'Can not find list data', success: false}
        }

        picked.data.status = PickedStatus.Processed
        delete picked.data.id
        await UpdatePick(picked.data, data.reference);
        
        const locations = await readLocations();

        //remove form storage
        for(const item of picked.data.items){

            let location = locations.find(f => f.code.toLowerCase().replace(" ","") === item.code.toLowerCase().replace(" ","") 
            && f.storage.toLowerCase().replace(" ","") === item.location.toLowerCase().replace(" ",""));
        
             if(location){
                 console.log(location);
                 location.quantity = Number(location.quantity) - Number(item.quantity);
                 console.log(location);
                 await UpdateLocation(location, location.id??''); 

                 const product = await readProductByCode(item.code)

                 if(product.data){
                    product.data.quantity = Number(product.data.quantity) - Number(item.quantity)
                    await UpdateProduct(product.data, product.data.id??'');
                 }
             }
        }
        

        return { success: true };


    } catch (err) {
        return { errorMessage: 'Error on creation check logs', success: false }
    }


}

const addInvoice = async (
    name : string,
    surname : string,
    contact : string,
    emailAddress : string,
    reference : string,
    number : string,  
    cartStatus : string,
    total : Number,
    createdBy : string,
    updateBy :string,
    cartItems : CartItemsDAO[] 

) => {
    try {

        const docRef = await addDoc(collection(db, 'Invoices'), {
            name,
            surname,
            contact,
            emailAddress,           
            reference,
            number,
            cartStatus,
            total,
            createdBy,
            updateBy,
            cartItems
        })
        return docRef

    } catch (err) {
        console.log('error saving invoice ', err);
        return null
    }

}

function generateRandomNumber(): string {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');

    const dateString = `${year}${month}${day}`;

    const randomDigits = Math.floor(100 + Math.random() * 900); // Generates a number between 100 and 999

    return `${dateString}${randomDigits}`;
}

