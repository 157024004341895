import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Defaultlayout from './_components/layouts/defaultlayout';
import LoginPage from './pages/public/login';
import Securelayout from './_components/layouts/Securelayout';
import DashboardView from './pages/secure/dashboard';
import ProductView from './pages/secure/product';
import ProductsView from './pages/secure/products';
import UserView from './pages/secure/user';
import UsersView from './pages/secure/users';
import SettingsView from './pages/secure/settings';
import Weblayout from './_components/layouts/Weblayout';
import HomeView from './pages/public/home';
import PickView from './pages/secure/pick';
import SaleView from './pages/secure/sales';
import InvoiceView from './pages/secure/invoice';
import ReceiveView from './pages/secure/receive';
import AboutView from './pages/public/about';
import ProductWeb from './pages/public/products';
import { AuthProvider } from './_components/authProvider';
import ResetPassword from './pages/public/resetPassword';


function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/' element={<Weblayout />}>
            <Route path='' element={<HomeView />}></Route>
            <Route path='/about' element={<AboutView />}></Route>
            <Route path='/products/:id' element={<ProductWeb />}></Route>
          </Route>
          <Route path='/' element={<Defaultlayout />}>
            <Route path='/sign-in' element={<LoginPage />}></Route>
            <Route path='/forgotpassword' element={<ResetPassword />}></Route>
          </Route>
          <Route path='/' element={<Securelayout />}>
            <Route path='/dashboard' element={<DashboardView />}></Route>
            <Route path='/product' element={<ProductView />}></Route>
            <Route path='/product/:id' element={<ProductView />}></Route>
            <Route path='/invoice/:id' element={<InvoiceView />}></Route>
            <Route path='/products' element={<ProductsView />}></Route>
            <Route path='/user' element={<UserView />}></Route>
            <Route path='/user/:id' element={<UserView />}></Route>
            <Route path='/users' element={<UsersView />}></Route>
            <Route path='/settings' element={<SettingsView />}></Route>
            <Route path='/pick' element={<PickView />}></Route>
            <Route path='/sales' element={<SaleView />}></Route>
            <Route path='/receive' element={<ReceiveView />}></Route>
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
