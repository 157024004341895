export interface ProductDAO{
    id?: string,
    name: string,
    price : number,
    quantity: number,
    description : string,
    brand: string,
    category: string,
    code: string,
    imageUrl : string,
    discount : boolean,
    discountPrice : number,
    featured: boolean,
    promote: boolean,
    images?: any[]
}

export interface CategoryDAO{
    id?: string,
    description: string
}

export interface BrandDAO{
    id?: string,
    description: string
}

export interface StorageDAO{
    id?: string,
    description: string
}

export enum SettingStat{
    Brand = "Brand",
    Category = "Category",
    Storage = "Storage"
}


