import { addDoc, collection } from "firebase/firestore";
import { ProductDAO } from "../_models/product";
import { db } from "../firebaseConfig";
import { readProducts } from "./productreadservice";


export async function CreateProduct(data: ProductDAO) {
    try {

        //check if email exists
        const users = await readProducts();

        if (users.find(x => x.code === data.code)) {
            return { errorMessage: 'This part number exists', success: false }
        }


        if (data.images) {
            data.imageUrl = data.images[0];
        }

        await addProduct(data.name, data.price, data.quantity, data.description, data.brand, data.category, data.code,
            data.imageUrl, data.discount, data.discountPrice, data.featured, data.promote, data.images);

        return { success: true };


    } catch (err) {
        console.log(err);
        return { errorMessage: 'Error on creation check logs', success: false }
    }
}

const addProduct = async (
    name: string,
    price: number,
    quantity: number,
    description: string,
    brand: string,
    category: string,
    code: string,
    imageUrl: string,
    discount: boolean,
    discountPrice: number,
    featured: boolean,
    promote: boolean,
    images?: any[],
    ) => {
    try {

        const docRef = await addDoc(collection(db, 'Products'), {
            name,
            price,
            quantity,
            brand,
            category,
            code,
            imageUrl,
            discount,
            discountPrice,
            featured,
            images,
            description,
            promote

        })
        return docRef

    } catch (err) {
        console.log('error saving product ', err);
        return null
    }

}

