import { deleteDoc, doc, updateDoc } from "firebase/firestore";

import { db } from "../firebaseConfig";


export async function UpdateLocation(data: any, id: string) {
    try {
        const docRef = doc(db, 'Locations', id);

        await updateLocation(docRef, data);

        return { success: true }

    } catch (err) {
        
        console.log(err);
        return { errorMessage: 'Error on updating check logs', success: false }
    }

}

const updateLocation = async (docRef: any, payload: any) => {

    return await updateDoc(docRef, payload);
}


