import React, { useEffect, useState } from 'react'
import { readInvoice } from '../../_services/invoicereadservice'
import { useNavigate, useParams } from 'react-router-dom'
import { CartDAO, CartItemsDAO, CartStatus } from '../../_models/cart';
import { readPick } from '../../_services/pickreadservice';
import { toast } from 'react-toastify';
import { readProducts } from '../../_services/productreadservice';
import BInputField from '../../_components/formfields/BInputField';
import { FieldValues, useForm } from 'react-hook-form';
import Spinner from '../../_components/spinner';
import BButton, { ButtonType } from '../../_components/button';
import { CreateInvoice } from '../../_services/invoicecreationService';
import BModal from '../../_components/modal';
import { PickDAO } from '../../_models/invertory';
import { validateNonZero } from '../../utility/validations';
import { UpdatePick } from '../../_services/pickmanagementservice';

enum ViewOption {
  Confirm = 1,
  Create = 2
}

export default function InvoiceView() {
  const [invoice, setInvoice] = useState<CartDAO>();
  const [pickedItems, setPickedItems] = useState<PickDAO>();
  const [pickItem, setPickItem] = useState<any>();
  const [amendView, setAmendView] = useState<boolean>(false);
  const [amendPick, setAmendPick] = useState<boolean>(false);
  const [viewOption, setViewOption] = useState<ViewOption>(ViewOption.Confirm);

  const {
    register: registerInv,
    handleSubmit: handleSubmitInv,
    setValue: setValueInv,
    formState: { errors }
  } = useForm<FieldValues>({});

  const {
    register: registerAmend,
    handleSubmit: handlesubmitAmend,
    setValue: setValueAmend,
    formState: { errors: amendErrors }
  } = useForm<FieldValues>({})
  const [isProcessing, setProcessing] = useState<boolean>(false);


  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setProcessing(true);

    getPickedItems();
    getInvoice();

    setProcessing(false);

  }, []);

  const getPickedItems = async () => {
    const results = await readInvoice(id ?? '');

    if (results.success) {
      return true;
    }

    const item = await readPick(id ?? '');

    if (!item) {
      return true;
    }

    setViewOption(ViewOption.Confirm);
    setPickedItems(item.data);

    return false

  }

  const getInvoice = async () => {

    const results = await readInvoice(id ?? '');

    if (!results.success) {

      const picked = await readPick(id ?? '');
      setProcessing(false);
      if (picked.success) {
        await loadingPickedInformation(picked.data ?? [])
        return;
      }

      toast.error(picked.errorMessage);
      navigate('/sales');
    }

    const invoice = results.data as CartDAO;
    setViewOption(ViewOption.Create);
    setInvoice(invoice);
    setValueInv('name', invoice.name);
    setValueInv('surname', invoice.surname);
    setValueInv('contact', invoice.contact);
    setValueInv('emailAddress', invoice.emailAddress);
    setValueInv('reference', invoice.reference);
    setValueInv('number', invoice.number);
    setValueInv('cartStatus', invoice.cartStatus);
    setValueInv('total', invoice.total);
    setValueInv('createdby', invoice.createdBy);
    setValueInv('updateBy', invoice.updateBy);

  }

  const _handleSubmit = async (data: FieldValues) => {
    setProcessing(true);

    const invoicing = {
      ...invoice,
      name: data.name,
      surname: data.surname,
      contact: data.contact,
      emailAddress: data.emailAddress,
    }

    const response = await CreateInvoice(invoicing as CartDAO)

    if (!response.success) {
      toast.error(response.errorMessage);
      return;
    }
    setProcessing(false);
    toast.success('Invoice created')
    navigate('/sales');
  }

  const _handleSubmitAmend = async (data: FieldValues) => {

    if (invoice?.cartItems) {

      invoice.cartItems = [...invoice.cartItems,
      { ...invoice.cartItems[pickItem.index], price: data.price, totalprice: Number(data.quantity) * Number(data.price) }]
      invoice.cartItems.splice(pickItem.index, 1);

      let totalPrice = 0;

      for (const item of invoice.cartItems) {
        totalPrice = totalPrice + item.totalprice.valueOf()
      }

      invoice.total = totalPrice
      setValueInv('total', totalPrice);
      setAmendView(false);
    }
  }

  const _handleSubmitPickAmend = async (data: FieldValues) => {

    if (pickedItems) {
      setProcessing(true);
      pickedItems.items = [...pickedItems.items,
      { ...pickedItems.items[pickItem.index], quantity: data.quantity, updated_date: new Date }]
      pickedItems.items.splice(pickItem.index, 1);

      const response = await UpdatePick(pickedItems, pickedItems.id ?? '')

      if (!response.success) {
        toast.error(response.errorMessage)
        return;
      }

      await getPickedItems();
      setAmendPick(false);
      setProcessing(false);

    }
  }

  const _navigateForward = () => {
    setProcessing(true);

    getInvoice();
    setViewOption(ViewOption.Create);
    setProcessing(false);

  }

  const _navigateBackward = () => {
    setProcessing(true);
    getPickedItems();
    setViewOption(ViewOption.Confirm)
    setProcessing(false);

  }

  const _amendCartItem = async (index: any) => {

    const item = { ...invoice?.cartItems[index], index: index };
    setValueAmend('description', item.description);
    setValueAmend('quantity', item.quantity);
    setValueAmend('price', item.price);
    setPickItem(item);
    setAmendView(true);

  }

  const _amendPickedItem = async (index: any) => {

    const item = { ...pickedItems?.items[index], index: index };
    setValueAmend('quantity', item.quantity);
    setPickItem(item);
    setAmendPick(true);

  }

  const loadingPickedInformation = async (picked: any) => {
    const products = await readProducts();
    let cartItems: CartItemsDAO[] = []
    let total: number = 0;

    for (const item of picked.items) {
      const product = products.find(f => f.code === item.code);
      if (!product) {
        return;
      }

      //combine items if there is the same item
      const existingItem = cartItems.find(f => f.code === item.code)
      if (existingItem) {
        item.quantity = Number(item.quantity) + Number(existingItem.quantity)
        total = Number(total) - Number(existingItem.totalprice)
        cartItems.splice(cartItems.findIndex(f => f.code === item.code), 1);
      }

      const totalAmount = product.price * item.quantity;
      total = total + Number(totalAmount);

      cartItems = [...cartItems, { description: product?.name, code: product?.code, price: product?.price, quantity: item.quantity, totalprice: totalAmount }]

    }

    setInvoice({
      cartItems: cartItems, total: total, name: '', surname: '', contact: '', emailAddress: '', reference: id, number: '', cartStatus: '', createdBy: '', updateBy: ''
    } as CartDAO)

    setValueInv('total', total);
    setValueInv('reference', id)
  }

  const AmendViewContent = () => (
    <form onSubmit={handlesubmitAmend(_handleSubmitAmend)} className='flex flex-col gap-3'>
      <h3>Amending Invoice Items</h3>
      <hr />
      <BInputField FieldName='description' Placeholder='Description' Register={registerAmend} Errors={errors} />
      <BInputField FieldName='price' Placeholder='Price' Register={registerAmend} Errors={errors}
        Validation={
          {
            required: 'This field is required',
            validate: (value) => {
              if (validateNonZero(value)) {
                return 'can not be zero'
              }
              return true;
            }
          }
        } />
      <div className='w-full flex gap-3'>
        <BButton Width='w-[100px]' CallBack={() => setAmendView(false)} ButtonStyle={ButtonType.Secondary} Label={'Cancel'} />
        <BButton Width='w-[150px]' Submit={() => { }} ButtonStyle={ButtonType.Primary} Label={`Edit`} />
      </div>
    </form>
  )

  const AmendPickedItem = () => (
    <form onSubmit={handlesubmitAmend(_handleSubmitPickAmend)} className='flex flex-col gap-3'>
      <h3>Amending Picked Items</h3>
      <hr />
      <BInputField FieldName='quantity' Placeholder='Amend Quantity' Register={registerAmend} Errors={errors}
        Validation={{
          required: 'This field is required',
          validate: (value) => {
            if (validateNonZero(value)) {
              return 'can not be zero'
            }
            return true;
          }
        }} />
      <div className='w-full flex gap-3'>
        <BButton Width='w-[100px]' CallBack={() => setAmendPick(false)} ButtonStyle={ButtonType.Secondary} Label={'Cancel'} />
        <BButton Width='w-[150px]' Submit={() => { }} ButtonStyle={ButtonType.Primary} Label={`Edit`} />
      </div>
    </form>
  )

  const _renderView = () => {
    switch (viewOption) {
      case ViewOption.Confirm:
        return (
          <div className='w-full md:w-6/12 bg-white rounded-xl p-4 flex flex-col gap-3'>
            <h3>Confirm Picked Items</h3>
            <hr />
            {
              pickedItems?.items.map((item, index) => (
                <div key={item.row} className='w-full flex flex-row bg-gradient-to-r from-blue-100 to-white rounded-md'>
                  <div className='bg-blue-300 w-[50px] rounded-l-md p-2 pl-4'>
                    {item.quantity}
                  </div>
                  <div className='w-10/12 flex justify-between'>
                    <div className='p-1 pl-3 '>
                      <p className='text-[12px]'>Part Number <span className='text-[10px] text-gray-500'>{item.code}</span></p>
                      <p className='text-[10px] text-gray-500'>Storage : {item.location}</p>
                    </div>
                    <div className='flex justify-end py-2'>
                      <button type='button' onClick={() => _amendPickedItem(index)} className='bg-gradient-to-r from-green-300 to-white rounded-full px-2 text-xs'>Amend</button>                    </div>
                  </div>
                </div>
              ))
            }
            <div className='w-full flex gap-3'>
              <BButton Width='w-[100px]' CallBack={() => _navigateForward()} ButtonStyle={ButtonType.Primary} Label={'Next'} />
            </div>
          </div>
        )

      case ViewOption.Create:
        return (
          <div className='w-full md:w-6/12 bg-white rounded-xl p-4 flex flex-col gap-3'>
            <h3>Invoice</h3>
            <hr />
            <form className='w-full flex flex-col gap-1 ' onSubmit={handleSubmitInv(_handleSubmit)}>
              <div className='w-full flex flex-row gap-3'>
                <div className='w-full md:w-1/2'>
                  <BInputField FieldName='name' Register={registerInv} Errors={errors} Placeholder='Client Name'
                    Validation={{
                      required: 'This field is required'
                    }}
                  />
                </div>
                <div className='w-full md:w-1/2'>
                  <BInputField FieldName='surname' Register={registerInv} Errors={errors} Placeholder='Client Surname'
                    Validation={{
                      required: 'This field is required'
                    }}
                  />
                </div>
              </div>
              <div className='w-full flex flex-row gap-3'>
                <div className='w-full md:w-1/2'>
                  <BInputField FieldName='contact' Register={registerInv} Errors={errors} Placeholder='Contact Number'
                    Validation={{
                      required: 'This field is required'
                    }}
                  />
                </div>
                <div className='w-full md:w-1/2'>
                  <BInputField FieldName='emailAddress' Register={registerInv} Errors={errors} Placeholder='Client Email'
                    Validation={{
                      required: 'This field is required'
                    }}
                  />
                </div>
              </div>
              <div className='w-full flex flex-row gap-3'>
                <div className='w-full md:w-1/2'>
                  <BInputField FieldName='reference' Register={registerInv} Errors={errors} Placeholder='Picked Reference'
                    Validation={{
                      required: 'This field is required'
                    }}
                    Disable={true}
                  />
                </div>
                <div className='w-full md:w-1/2'>
                  <BInputField FieldName='number' Register={registerInv} Errors={errors} Placeholder='Invoice No'

                    Disable={true}
                  />
                </div>
              </div>
              <div className='w-full md:w-1/2'>
                <BInputField FieldName='total' Register={registerInv} Errors={errors} Placeholder='Total Amount'
                  Validation={{
                    required: 'This field is required'
                  }}
                  Disable={true}
                />
              </div>
              <div className='w-full overflow-x-auto'>
                <table className="w-full">
                  <thead className='text-black'>
                    <tr>
                      <th className='text-black'>Description</th>
                      <th className='text-black'>Quantity</th>
                      <th className='text-black'>Unit Price</th>
                      <th className='text-black'>Total Price</th>
                      <th className='text-black'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      invoice?.cartItems.map((cartItem, index) => (
                        <tr key={cartItem.code}>
                          <td className='text-xs pl-3 border-t border-t-blue-300'>{cartItem.description}</td>
                          <td className='text-xs pl-3 border-t border-t-blue-300'>{cartItem.quantity.toString()}</td>
                          <td className='text-xs pl-3 border-t border-t-blue-300'>R {cartItem.price.toString()}</td>
                          <td className='text-xs pl-3 border-t border-t-blue-300'>R {cartItem.totalprice.toString()}</td>
                          <td className='text-xs pl-3 border-t border-t-blue-300 flex gap-3'>
                            {!invoice?.number && <button type='button' onClick={() => _amendCartItem(index)} className='bg-gradient-to-r from-green-300 to-white rounded-full px-2'>Amend</button>}
                            <button className='bg-gradient-to-r from-red-400 to-white rounded-full px-2'>Delete</button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <div className='w-full flex gap-3'>
               {!invoice?.number && <BButton Width='w-[100px]' CallBack={() => _navigateBackward()} ButtonStyle={ButtonType.Tertiary} Label={'Back'} />}
                <div className='flex w-full justify-end gap-3'>
                  <BButton Width='w-[100px]' CallBack={() => navigate('/sales')} ButtonStyle={ButtonType.Secondary} Label={'Cancel'} />
                  <BButton Width='md:w-[150px]' Submit={() => { }} ButtonStyle={ButtonType.Primary} Label={invoice?.number ? `Save` : `Create `} />
                </div>
              </div>
            </form>
          </div>
        )
    }
  }

  return (
    <div className='w-full flex justify-center'>
      {isProcessing && <Spinner />}
      {amendView && <BModal Content={AmendViewContent()} />}
      {amendPick && <BModal Content={AmendPickedItem()} />}
      {_renderView()}
    </div>
  )
}


