import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { Link, Outlet } from 'react-router-dom'
import { FaSearch, FaHome } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useState } from "react";
import { readProducts } from "../../_services/productreadservice";
import { ProductDAO } from "../../_models/product";
import BModal from "../modal";
import BButton, { ButtonType } from "../button";
import Spinner from "../spinner";
import Footer from "../footer";

export default function Weblayout() {

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 640;
  const [searchInput, setSearchInput] = useState<string>('');
  const [isProcess, setIsProcess] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductDAO>();

  const onSearchInput = (input: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(input.target.value);
  }

  const onSearch = async () => {
    setIsProcess(true);
    const products = await readProducts();

    const product = products.find(f => f.code.toLowerCase() === searchInput.toLowerCase());
    setIsProcess(false);
    if (!product) {
      toast.error('Part number search was not found');
      return;
    }

    setProduct(product);

  }

  const _renderProductScreen = (
    <div className='w-full flex flex-col gap-3 p-3'>
      <p className='text-sm'>Search Result</p>
      <hr />
      <table className='w-full text-xs'>
        <tbody>
          <tr>
            <td><img src={product?.imageUrl} alt="image" className="w-[100px] h-[100px]"/></td>
            <td>
              <table className="w-full">
                <tbody>
                  <tr><td>Product : </td><td>{product?.name}</td></tr>
                  <tr><td>Part Number : </td><td>{product?.code}</td></tr>
                  <tr><td>Category : </td><td>{product?.category}</td></tr>
                  <tr><td>Brand : </td><td>{product?.brand}</td></tr>
                  <tr><td>Price : </td><td>R {product?.price}</td></tr>
                </tbody>
              </table>
            </td>
          </tr>

        </tbody>
      </table>
      <BButton CallBack={() => setProduct(undefined)} ButtonStyle={ButtonType.Secondary} Label='Cancel' />
    </div>
  )

  const navigationbar = () => {


    return (

      <div className='w-full flex flex-col z-40'>
        {isProcess && <Spinner/> }
        {product && <BModal Content={_renderProductScreen} />}
        <div className='w-full fixed flex flex-row bg-gradient-to-r from-black to-blue-300 p-3 text-sm pt-6'>
          <div className='w-4/12 sm:w-2/12'><a href="/"><img src='/basLogo.png' className='md:w-[100px]' /></a></div>
          <div className='sm:w-10/12 flex flex-col md:flex-row gap-3 align-middle pt-2'>
            <div className="w-full md:w-8/12 flex md:flex-row gap-3 p-4">
              <input type="text" className='w-full md:w-10/12 rounded-full border border-gray p-[5.5px] text-sm px-3' onChange={onSearchInput} placeholder="Search by part number..." />
              <button type="button" onClick={onSearch} className='flex w-[60px] sm:w-2/12 gap-2 text-xs rounded-full bg-gradient-to-tr from-blue-400 to-white p-2 pt-3 justify-center'><FaSearch /> <span className="hidden md:block">Search</span> </button>

            </div>
            <div className="flex text-white md:text-black flex-row gap-3 md:pt-7 text-sm md:text-xs text-right ">
              <Link to={''} className="flex gap-3"><FaHome size={15} /> Home</Link>
              <Link to={'/about'}>About</Link>
            </div>

          </div>
        </div>
        <div className='hidden w-full p-3 bg-white mt-[115px] text-xs md:flex flex-row'>
          <span className='flex flex-row gap-3 border-r border-black pr-9'><IoMdMail size={17} />  info@baspares.co.za</span>
          <span className='flex flex-row gap-3 border-r border-black pr-9 pl-5'><FaPhoneAlt size={17} />  +27 67 075 3669</span>
          <span className='flex flex-row gap-3 pl-5'><FaLocationDot size={17} />  141 Industrial Rd, Pretoria Industrial, Pretoria, 0183</span>
        </div>
      </div>

    )
  }

  return (
    <div className='w-full min-h-screen bg-slate-200'>
      {navigationbar()}
      <main className='p-4 md:mx-28 pt-[145px] md:pt-[35px]'>
        <Outlet />
        <ToastContainer />
      </main>
      {<Footer/>}
      <div >

      </div>
    </div>
  )
}
