import React, { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import BInputField from '../../_components/formfields/BInputField'
import BRadioButton from '../../_components/formfields/BRadioButton'
import BButton, { ButtonType } from '../../_components/button'
import { useNavigate, useParams } from 'react-router-dom'
import { CreateUser } from '../../_services/usercreationservice'
import { UserDAO } from '../../_models/user'
import Spinner from '../../_components/spinner'
import { readUser } from '../../_services/userreadservice'
import { UpdateUser } from '../../_services/usermanagementservice'
import { toast } from 'react-toastify'


export default function UserView() {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<FieldValues>();
    const [processing, setProcessing] = useState(false);
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() =>{
        if(id){
            setProcessing(true);

            const fetchUser = async ()=>{
                const user = await readUser(id);
                setValue('name', user.data?.name)
                setValue('surname', user.data?.surname)
                setValue('emailAddress', user.data?.emailAddress)
                setValue('role', user.data?.role)
                setProcessing(false);

            }
            fetchUser();
        }
    },[])



    const handleSave = async (data: FieldValues) => {
        
        setProcessing(true);
        
        const response = !id ? await CreateUser(data as UserDAO) : await UpdateUser(data as UserDAO, id);

        setProcessing(false);
        if (!response.success) {
            toast.error(response.errorMessage);
            return;
        }

        navigate('/users');
    }

    return (
        <div className='flex justify-center w-full'>
            {processing && <Spinner />}

            <div className='rounded-xl bg-white w-full md:w-4/12 p-4'>
                <h3>User Details</h3>
                <hr />
                <form onSubmit={handleSubmit(handleSave)} className='flex flex-col gap-3'>
                    <BInputField
                        FieldName='name'
                        Placeholder='Name'
                        Register={register}
                        Errors={errors}
                        Validation={{
                            required: 'This field is required'
                        }}
                    />
                    <BInputField
                        FieldName='surname'
                        Placeholder='Surname'
                        Register={register}
                        Errors={errors}
                        Validation={{
                            required: 'This field is required'
                        }}
                    />
                    <BInputField
                        FieldName='emailAddress'
                        Placeholder='Email Address (must be unique)'
                        Register={register}
                        Errors={errors}
                        Validation={{
                            required: 'This field is required'
                        }}
                    />
                    <BRadioButton
                        RadioOptions={['Admin', 'User', 'SuperAdmin','Floor']}
                        FieldName='role'
                        Placeholder='Admin'
                        Register={register}
                        Errors={errors}
                        Validation={{
                            required: 'Select one option'
                        }}
                    />
                    <div className='flex gap-3 w-full'>

                        <BButton CallBack={() => navigate('/users')} Width='w-[100px]' ButtonStyle={ButtonType.Secondary} Label={'Cancel'} />
                        <BButton Submit={() => { }} Width='w-[100px]' ButtonStyle={ButtonType.Primary} Label={'Save'} />
                    </div>
                </form>
            </div>
        </div>
    )
}
