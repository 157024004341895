
import React from 'react'
import { TableColumnProps } from '../_models/table'

export default function BTablecolumn({label, column, type, render}: TableColumnProps) {
  return (
    <colgroup>
        <col className={column} data-type={type} content={render}/>
    </colgroup>
  )
}
