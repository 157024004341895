
import React from 'react'
import { TfiEmail } from "react-icons/tfi";
import { MdLocalPhone } from "react-icons/md";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from 'react-router-dom';

export default function Footer() {
  const handleWhatsapp = () => {
        
    const message = 'Hi'
    const url = `https://api.whatsapp.com/send?phone=${+27670753669}&text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };
  
  return (
    <div className='w-full p-5 bg-gradient-to-tr from-blue-300 to-black text-white px-28 text-sm z-40'>
      <div className='w-full flex flex-col md:flex-row gap-3 pb-4'>
        <div className='w-full text-center md:w-1/4 md:text-left'>
          <h4 className='font-semibold text-lg md:mt-3'>Quick links</h4>
          <div className='w-full'>
            <p><Link className='inline-block' to={'/'}>Home</Link></p>
            <p><Link className='inline-block' to={'/about'}>About</Link></p>
          </div>
        </div>
        <div className='w-full mt-3 text-center md:w-1/4 md:text-left'>
          <h4 className='font-semibold text-lg'>Our Story</h4>
          <div className='w-full'>
            <p>With years of experience in the automotive industry, we specialize in providing genuine and aftermarket parts for all Mercedes-Benz models</p>
          </div>
        </div>
        <div className='w-full mt-3 text-center md:w-1/4 md:text-left'>
          <h4 className='font-semibold text-lg'>Get in touch</h4>
          <div className='w-full'>
            <p><TfiEmail size={20} className='inline-block' /> <Link className='inline-block' to={'mailTo:info@baspares.co.za'}>info@baspares.co.za</Link></p>
            <p><MdLocalPhone size={20} className='inline-block' /> <Link className='inline-block' to={'tel:061 517 2645'}>067 075 3669 </Link></p>            
            <button onClick={handleWhatsapp}><FaWhatsapp size={20} className='inline-block' /> WhatsApp</button>
          </div>
        </div>
        <div className='w-full mt-3 text-center md:w-1/4 md:text-left'>
          <h4 className='font-semibold text-lg'>Physical address</h4>
          <div className='w-full'>
            <p>141 Industrial Rd</p>
            <p>Pretoria Industrial</p>
            <p>Pretoria</p>
            <p>South Africa</p>
          </div>
        </div>
      </div>
      <hr />
      <p className='text-[10px] text-center'>Designed by<a href='https://koswell.co.za' target="_blank"> Koswell Pty Ltd</a> © 2024 Baspares | All Rights Reserved.</p>
    </div>
  )
}
