import React, { useEffect, useRef } from 'react';

interface CloudinaryUploadWidgetProps {
  onUpload: (result: any) => void;
}

const CloudinaryUploadWidget: React.FC<CloudinaryUploadWidgetProps> = ({ onUpload }) => {
  const widgetRef = useRef<any>(null); // This ref will store the widget instance

  useEffect(() => {
    // Initialize Cloudinary widget and store in widgetRef
    widgetRef.current = window.cloudinary.createUploadWidget(
      {
        cloudName: 'dgwcpz6mq',  // Replace with your Cloudinary cloud name
        uploadPreset: 'ProductImages',  // Replace with your upload preset
      },
      (error: any, result: any) => {
        if (!error && result && result.event === 'success') {
          onUpload(result.info); // Handle successful upload
        }
      }
    );
  }, [onUpload]);

  // Handle widget opening on button click
  const handleUploadClick = () => {
    if (widgetRef.current) {
      widgetRef.current.open(); // Open widget instance
    }
  };

  return <button type='button' onClick={handleUploadClick} className='w-[100] text-xs rounded-full bg-gradient-to-tr from-blue-400 to-white p-2'>Upload Image</button>;
};

export default CloudinaryUploadWidget;
