export interface TableColumnProps {
    label: string;
    column: string;
    type?: Datatype;
    render?: any
}

export enum Datatype {
    Text,
    Checkbox,
    Currency,
    Date
}