import React, { ReactNode } from 'react'

interface Paramters{
    Content : ReactNode,
    Class?: string
}

export default function BCard({Content, Class} : Paramters) {
  return (
    <div className='w-full flex flex-col p-2'>
        <div className={`${Class} h-min bg-white rounded-xl p-4`}>
            {Content}
        </div>
    </div> )
}
