import React from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export default function Defaultlayout() {
  return (
    <div className='w-full'>
        <Outlet/>
        <ToastContainer />
    </div>
  )
}
