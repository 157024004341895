import React, { useEffect, useState } from 'react'
import BCarsouselList from '../../_components/slicker-carousel'
import { BrandDAO, CategoryDAO, ProductDAO } from '../../_models/product'
import { readProducts } from '../../_services/productreadservice';
import { readBrands, readCategories } from '../../_services/settingsreadservice';
import Spinner from '../../_components/spinner';
import PromotionSlider from '../../_components/promotion-slider';

export default function HomeView() {
  const [products, setProducts] = useState<ProductDAO[]>([]);
  const [promotes, setPromotes] = useState<ProductDAO[]>([]);
  const [brands, setBrands] = useState<BrandDAO[]>([]);
  const [productByCategory, setProductByCategory] = useState<{ category: string, products: ProductDAO[] }[]>([])
  const [processing, setProcessing] = useState(false);



  useEffect(() => {
    setProcessing(true);

    const fetchData = async () => {
      const fetchedProducts = await readProducts();
      const fetchedCategory = await readCategories();
      const filtered = fetchedProducts.filter(f => f.promote === true);
      setPromotes(filtered);
      setProducts(fetchedProducts);
      setBrands(fetchedCategory);

      // Now call the function to organize products by brand
      ProductByBrand(fetchedCategory, fetchedProducts);

      setProcessing(false);

    };

    fetchData();

  }, []);


  const ProductByBrand = (category: CategoryDAO[], products: ProductDAO[]) => {

    const categoryProductList = category.map(category => {
      const result = products.filter(product => product.category === category.description);
      return {
        category: category.description,
        products: result
      };
    });

    setProductByCategory(categoryProductList);

  }


  return (
    <div className='mt-4'>
      <div className='w-full flex flex-col md:flex-row gap-4 md:h-[400px]'>          
          <PromotionSlider Products={promotes.length > 0 ? promotes : products}/>        
        <div className='w-full hidden md:flex md:w-2/12 flex-col gap-4'>
          <div className='w-full h-[200px] overflow-hidden  rounded-xl'>
            <img className='w-full h-auto rounded-xl' src='rim.jpg' alt='image' />
          </div>
          <div className='w-full h-[200px] overflow-hidden  rounded-xl'>
            <img className='w-full h-full rounded-xl' src='headlights.jpg' alt='image' />
          </div>
        </div>
      </div>
      {processing && <Spinner />}
      {
        productByCategory.map((item) => (
          <BCarsouselList key={item.category} Products={item.products} Header={item.category} />
        ))
      }

    </div>
  )
}
