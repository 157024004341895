import React, { useEffect } from 'react'
import { ProductDAO } from '../_models/product';
import Slider from 'react-slick';

interface Parameters {
    Products: ProductDAO[];
}

export default function PromotionSlider({ Products }: Parameters) {

    var settings = {
        dots: false,
        infinite: Products.length === 1 ? false : true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows : false
    };

    useEffect(()=>{
        console.log(Products);
    },[]);

    return (
        <div className='w-full md:w-10/12 h-[400px] relative'>
            <Slider {...settings}>
                {
                    Products.map((product) => (
                        <img key={product.code} className='w-full rounded-xl h-[400px]' src={product.imageUrl} alt='image' />
                    ))
                }
            </Slider >
        </div >
    )
}
