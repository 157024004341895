import { useEffect, useState } from 'react'
import BTableData from '../../_components/btable'
import BTablecolumn from '../../_components/btablecolumns'
import BCard from '../../_components/bcard'
import { FaFilter, FaSearch } from 'react-icons/fa'
import { BrandDAO, CategoryDAO, SettingStat, StorageDAO } from '../../_models/product'
import { readBrands, readCategories, readStorage } from '../../_services/settingsreadservice'
import Spinner from '../../_components/spinner'
import { CreateBrand, CreateCategory, CreateStorage } from '../../_services/settingscreationservice'
import BInputFieldbutton from '../../_components/formfields/BInputFieldbutton'
import { FaTrash } from 'react-icons/fa6'
import { DeleteBrand, DeleteCategory, DeleteStorage } from '../../_services/settingsmanagement'
import { toast } from 'react-toastify'

export default function SettingsView() {

    const [brandList, setBrandList] = useState<BrandDAO[]>([]);
    const [storageList, setStorageList] = useState<StorageDAO[]>([]);
    const [categoryList, setCategoryList] = useState<CategoryDAO[]>([]);
    const [processing, setProcessing] = useState(false);

    const staticData = ['Brand', 'Category', 'Storage'];

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setProcessing(true);
        setCategoryList(await readCategories());
        setBrandList(await readBrands());
        setStorageList(await readStorage());
        setProcessing(false);
    }


    const handleBrand = async (value: string) => {

        if (value === '') {
            return;
        }

        setProcessing(true);
        const response = await CreateBrand({ description: value } as BrandDAO);
        setProcessing(false);
        if (!response.success) {
            console.log(response.errorMessage);
            return;
        }

        await fetchData();
    }

    const handleCategory = async (value: string) => {

        if (value === '') {
            return;
        }

        setProcessing(true);
        const response = await CreateCategory({ description: value } as BrandDAO);
        setProcessing(false);
        if (!response.success) {
            console.log(response.errorMessage);
            return;
        }

        await fetchData();
    }

    const handleStorage = async (value: string) => {

        if (value === '') {
            return;
        }

        setProcessing(true);
        const response = await CreateStorage({ description: value } as BrandDAO);
        setProcessing(false);
        if (!response.success) {
            console.log(response.errorMessage);
            return;
        }

        await fetchData();
    }

    const deleteObject = async (id: string, setting: string) => {

        setProcessing(true);

        let response: {};

        switch (setting) {
            case SettingStat.Brand:

                response = await DeleteBrand(id || '');

                if (!response) {
                    toast.error('Failed to delete');
                    setProcessing(false);
                    return;
                }

                break;
            case SettingStat.Category:

                response = await DeleteCategory(id || '');

                if (!response) {
                    toast.error('Failed to delete');                 
                    setProcessing(false);
                    return;
                }

                break;
            case SettingStat.Storage:
                response = await DeleteStorage(id || '');

                if (!response) {
                    toast.error('Failed to delete');
                    setProcessing(false);
                    return;
                }

                break;
        }
        await fetchData();
        setProcessing(false);

    }

    const deleteBrand = (data: any) => (
        <button className='text-xs rounded-full bg-gradient-to-tr from-red-400 to-white flex gap-2 py-1 px-2' onClick={() => deleteObject(data.id, SettingStat.Brand)}><FaTrash /> Delete </button>
    )
    const deleteCategory = (data: any) => (
        <button className='text-xs rounded-full bg-gradient-to-tr from-red-400 to-white flex gap-2 py-1 px-2' onClick={() => deleteObject(data.id, SettingStat.Category)}><FaTrash /> Delete </button>
    )
    const deleteStorage = (data: any) => (
        <button className='text-xs rounded-full bg-gradient-to-tr from-red-400 to-white flex gap-2 py-1 px-2' onClick={() => deleteObject(data.id, SettingStat.Storage)}><FaTrash /> Delete </button>
    )


    const _renderView = (view: string) => {
        switch (view) {
            case SettingStat.Brand:
                return (
                    <div className='w-full flex flex-col'>
                        <h3>Brands</h3>
                        <hr />
                        <div className='flex flex-col w-full my-3'>
                            <div className='flex flex-row gap-4 text-blue-400'><button><FaFilter /></button><button><FaSearch /></button></div>
                            <BInputFieldbutton Label={'Add Brand'} Placeholder={'Brand Name'} onSubmit={handleBrand} />
                        </div>
                        <BTableData dataSource={brandList} actions={deleteBrand}>
                            <BTablecolumn label={'id'} column={''} />
                            <BTablecolumn label={'Name'} column={'description'} />
                        </BTableData>
                    </div>

                )
            case SettingStat.Category:

                return (
                    <div className='w-full flex flex-col'>
                        <h3>Category</h3>
                        <hr />
                        <div className='flex flex-col w-full my-3'>
                            <div className='flex flex-row gap-4 text-blue-400'><button><FaFilter /></button><button><FaSearch /></button></div>
                            <BInputFieldbutton Label={'Add Category'} Placeholder={'Category Name'} onSubmit={handleCategory} />
                        </div>

                        <BTableData dataSource={categoryList} actions={deleteCategory}>
                            <BTablecolumn label={'id'} column={''} />
                            <BTablecolumn label={'Name'} column={'description'} />
                        </BTableData>
                    </div>
                )

            case SettingStat.Storage:

                return (
                    <div className='w-full flex flex-col'>
                        <h3>Storage</h3>
                        <hr />
                        <div className='flex flex-col w-full my-3'>
                            <div className='flex flex-row gap-4 text-blue-400'><button><FaFilter /></button><button><FaSearch /></button></div>
                            <BInputFieldbutton Label={'Add Storage'} Placeholder={'Storage Name'} onSubmit={handleStorage} />
                        </div>
                        <BTableData dataSource={storageList} actions={deleteStorage}>
                            <BTablecolumn label={'id'} column={''} />
                            <BTablecolumn label={'Name'} column={'description'} />
                        </BTableData>
                    </div>
                )
        }
    }

    return (
        <div className='flex flex-col'>
            {processing && <Spinner />}
            <h3>Settings</h3>
            <hr />
            <div className='flex flex-col md:flex-row mb-[100px]'>
                {
                    staticData.map((stat) => (
                        <div key={stat} className='w-full md:w-4/12'>
                            <BCard Class='h-[500px] overflow-y-auto' Content={_renderView(stat)} />
                        </div>
                    ))
                }
            </div>

        </div>
    )
}


